import _ from 'lodash';
import { getBRLocale } from 'src/utils/helpers/i18n';
import { request } from 'src/utils/request';
import { BetSourceStatus } from 'src/utils/types/admin-v2/sportsbook';
import { GetBlockCountries } from 'src/utils/types/auth';
import { I18nMatchStatus, MatchStatus } from 'src/utils/types/dashboard';
import { BaseResponse, PaginationResponse } from 'src/utils/types/response';
import { BetSource } from 'src/utils/types/sportsbook';

interface KeyValueObj {
  key: string;
  value: any;
}

export const getAllConfigs = async () => {
  const { data } = await request.get<BaseResponse<KeyValueObj[]>>(
    `/fantasy-configs/get-all-configs`,
  );

  const keyValue: Record<string, string> = _.chain(data.data)
    .keyBy((o) => o.key)
    .mapValues((o) => o.value)
    .value();

  return keyValue;
};

export const getUserSettings = async () => {
  const { data } =
    await request.get<BaseResponse<KeyValueObj[]>>(`/user-setting`);

  return _.reduce(
    data.data,
    (prev, curr) => {
      prev[curr.key] = curr.value;
      return prev;
    },
    {} as Record<string, any>,
  );
};

export const updateUserSetting = async (payload: {
  key: string;
  value: any;
}) => {
  await request.post<BaseResponse<KeyValueObj[]>>(`/user-setting/key`, payload);
};

export const getMatchStatus = async (locale: string) => {
  const lang = getBRLocale(locale);
  const { data } = await request.get<PaginationResponse<I18nMatchStatus>>(
    '/match-status/all',
    { params: { size: '-1', lang } },
  );

  return _.chain(data.data.items)
    .filter({ source: BetSource.BET_RADAR })
    .map(({ code, description, languages }) => {
      return {
        code,
        description: _.get(languages, [lang, 'description'], description),
      } satisfies MatchStatus;
    })
    .value();
};

export const getBlackListCountry = async () => {
  const { data } = await request.get<GetBlockCountries>(
    '/blacklist/list-country',
  );

  return data.data;
};
export const getConnectionStatus = async () => {
  const { data } = await request.get<PaginationResponse<BetSourceStatus>>(
    `/m-crawler/connection-status`,
  );
  return data.data.items;
};
