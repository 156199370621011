import { getWindow } from './etc';

export const showIntercom = () => {
  getWindow()?.Intercom?.('show');
};

// const hideIntercom = () => {
//   getWindow()?.Intercom?.('hide');
// };

// export const updateIntercom = (profile?: ProfileData) => {
//   const w = getWindow();
//   if (!w || !profile) return;

//   const prev = w.intercomSettings;

//   w.Intercom?.('update', {
//     ...prev,
//     email: profile.email,
//     user_id: String(profile.id),
//   });
// };

export const updateConfigIntercom = (cfg?: Intercom_.IntercomSettings) => {
  const w = getWindow();
  if (!w) return;
  const prev = w.intercomSettings;
  w.Intercom?.('update', {
    ...prev,
    ...cfg,
  });
};

export const shutdownIntercom = () => {
  getWindow()?.Intercom?.('shutdown');
};
