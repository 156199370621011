import { BrMarketStatus } from '../constants/common-constant';
import { BetSource } from './sportsbook';

export interface BaseOutcome {
  _id: string | null;
  eventId: string;
  marketId: string;
  marketSpecifiers: string | null;
  producerId: ProducerId | number | string;
  outcomeId: string;
  currentOdd: number | string;
  source: BetSource;
  key: string;
  outcomeGroupName?: string;
  marketName?: string;
  outcomeName?: string;
  nextBetStop?: string; // outright
  marketStatus: BrMarketStatus;
  favorite?: number;
  probabilities?: string;
  updatedAt?: string;
  outcomeActive?: OutcomeActive;
  priority?: number;
}

export enum OutcomeActive {
  ACTIVE = '1',
  INACTIVE = '0',
}
export enum ProducerId {
  LIVE = 1,
  ODDIN_PREMATCH = 1,
  ODDIN_LIVE = 2,
  PREMATCH = 3,
  LDO = 4,
}
