import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    popoverPaper: {
      marginTop: 16,
      minWidth: 200,
      border: `1px solid ${theme.palette.divider}`,
    },

    backdrop: {
      backgroundColor: 'transparent !important',
    },

    item: {
      height: 42,
      padding: '0px 16px',
      fontWeight: 500,
      alignItems: 'center',
    },
  };
});
