import BigNumber from 'bignumber.js';
import { CRYPTO_DP, FIAT_DP } from 'src/utils/constants/common-constant';
import { BN, NotRoundBN } from 'src/utils/helpers/big-number';

export const toUsd = (
  crypto: BigNumber.Value,
  price: string | undefined,
  decimal = FIAT_DP,
) => {
  return new BN(crypto).times(price ?? '1').toFixed(decimal);
};

export const toCrypto = (
  usd: BigNumber.Value | undefined,
  price: string | undefined,
  decimal = CRYPTO_DP,
) => {
  return new BN(usd ?? '0').div(price ?? '1').toFixed(decimal);
};

export const toFormat = (val: BigNumber.Value, fiat: boolean, rate: string) => {
  const _rate = fiat ? rate : '1';
  const raw = new NotRoundBN(val)
    .multipliedBy(_rate)
    .toFixed(fiat ? FIAT_DP : CRYPTO_DP);

  return raw;
};
