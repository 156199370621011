export const USER_JWT_KEY = 'ubet-jwt';
export const USER_PREF_KEY = 'ubet-pref';
export const USER_FINGERPRINT = 'ubet-fp';
export const LOCALE_KEY = 'locale';

// SB = BetSlip
export const DESKTOP_BS_WIDTH = 370;
export const DESKTOP_NAV_BAR_HEIGHT = 64;
export const MOBILE_NAV_BAR_HEIGHT = 58;
export const MOBILE_TOP_NAV_HEIGHT = 75;

// https://mikemcl.github.io/bignumber.js/#toFr
export const MAX_DENOMINATOR = 100;

export const EMAIL_DOMAIN = 'UBET';

export const MIN_STAKE_IN_USD = '0.5';

export const IGNORE_UNAUTHORIZED_HEADER = 'ignore-unauthorized';
export const LOCALES = ['en', 'pt-br', 'fr-ca'];
export const DEFAULT_LOCALE = LOCALES[0];
