export enum CoinType {
  INTERNAL = 'INTERNAL',
  FIAT = 'FIAT',
  BEP20 = 'BEP20',
  BNB = 'BNB',
  BTC = 'BTC',
  ERC20 = 'ERC20',
  ETH = 'ETH',
  BRC20 = 'BRC20',
  BSC20 = 'BSC20',
  KRC20 = 'KRC20',
}
