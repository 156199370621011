export enum AuthStatus {
  Verifying = 0,
  Active = 1,
  Inactive = 2,
  Fail = -1,
}

export enum RoleType {
  Admin = 1,
  User = 0,
  Develop = 2,
  SuperAdmin = 4,
}

export enum AccountLevel {
  LEVEL_0 = '0',
  LEVEL_1_1 = '1.1',
  LEVEL_1_2 = '1.2',
  LEVEL_2 = '2',
  IDL = '-1',
}
