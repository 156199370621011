import moment from 'moment';
import type { CampaignEvent } from 'src/api/campaign';
import { OutcomeActive, ProducerId } from 'src/utils/types/outcome';
import { BetSlipItemInterface, BetSource } from 'src/utils/types/sportsbook';
import { BrMarketStatus } from '../constants/common-constant';
import { generateAlias } from './ticket';

export enum CampaignStatus {
  ACTIVE = '1',
  INACTIVE = '0',
  SUSPENDED = '2',
}

const statusMapping = {
  [CampaignStatus.ACTIVE]: BrMarketStatus.ACTIVE,
  [CampaignStatus.INACTIVE]: BrMarketStatus.CANCELLED,
  [CampaignStatus.SUSPENDED]: CampaignStatus.SUSPENDED,
};

export const campaignToBetslip = (data: {
  event: CampaignEvent;
  outcomeId: string;
}) => {
  const { event, outcomeId } = data;

  const market = event.markets.find(
    (x) => !!x.outcomes.find((o) => o._id === outcomeId),
  );
  if (!market) return;

  const outcome = market.outcomes.find((x) => x._id === outcomeId);
  if (!outcome) return;

  return {
    _id: outcome._id,
    source: BetSource.CAMPAIGN,

    alias: generateAlias({
      _id: outcome._id,
      eventId: event.eventURN,
      source: BetSource.CAMPAIGN,
    }),
    eventName: event.name,
    marketName: market.name,
    outcomeName: outcome.name,
    marketStatus: statusMapping[event.status],
    currentOdd: outcome.odds,
    outcomeActive: OutcomeActive.ACTIVE,
    singleStakeRange: {
      min: event.minStake,
      max: event.maxStake,
    },
    _rudderstack: { tournamentId: event.eventURN, sportId: market.sportId },

    key: event.eventURN,

    producerId: ProducerId.PREMATCH,
    eventId: event.eventURN,
    marketId: market._id,
    marketSpecifiers: null,
    outcomeId: outcome._id,
  } as BetSlipItemInterface;
};

export const isActiveCampaign = (data?: CampaignEvent) => {
  return (
    data &&
    data.status == CampaignStatus.ACTIVE &&
    moment().isBetween(data.startDateTime, data.endDateTime)
  );
};
