import { atom } from 'jotai';
import _ from 'lodash';
import {
  getAllConfigs,
  getBlackListCountry,
  getConnectionStatus,
  getMatchStatus,
  getUserSettings,
  updateUserSetting,
} from 'src/api/cfg';
import { stopBetSlips } from 'src/utils/helpers/async-helpers';
import { BetSourceStatus } from 'src/utils/types/admin-v2/sportsbook';
import { IBlockCountry } from 'src/utils/types/auth';
import { MatchStatus } from 'src/utils/types/dashboard';
import { localeAtom } from './auth';
import { betSlipsAtom } from './bet-slip';
import { OddsAcceptance, userPrefAtom } from './layout';

const _blacklistCountriesAtom = atom<IBlockCountry[]>([]);

export const blacklistCountriesAtom = atom(
  (get) => get(_blacklistCountriesAtom),
  async (get, set) => {
    const items = await getBlackListCountry();
    set(_blacklistCountriesAtom, items);
    return items;
  },
);

const _matchStatusesAtom = atom<MatchStatus[]>([]);

export const matchStatusesAtom = atom(
  (get) => get(_matchStatusesAtom),
  async (get, set) => {
    const locale = get(localeAtom);

    const data = await getMatchStatus(locale);
    set(_matchStatusesAtom, data);

    return data;
  },
);

const _betSourcesAtom = atom<BetSourceStatus[]>([]);

export const betSourcesAtom = atom(
  (get) => get(_betSourcesAtom),
  async (get, set) => {
    const sources = await getConnectionStatus();
    set(_betSourcesAtom, sources);

    set(betSlipsAtom, (prev) => {
      return stopBetSlips(prev, sources);
    });

    return sources;
  },
);

const _configsAtom = atom<Record<string, string>>({});

export const configsAtom = atom(
  (get) => get(_configsAtom),
  async (get, set) => {
    const cfg = await getAllConfigs();
    set(_configsAtom, cfg);
  },
);

const _userSettingAtom = atom<{
  odds_acceptance?: OddsAcceptance | null;
}>({});

export const userSettingAtom = atom(
  (get) => get(_userSettingAtom),
  async (get, set, payload?: { key: string; value: any }) => {
    if (!payload) {
      const obj = await getUserSettings();
      set(_userSettingAtom, obj);
      return;
    }

    try {
      await updateUserSetting(payload);
    } catch (err) {}
    set(_userSettingAtom, (prev) => ({
      ...prev,
      [payload.key]: payload.value,
    }));
  },
);

export const remoteOddsAcceptanceAtom = atom(
  (get) =>
    _.get(
      get(_userSettingAtom),
      ['odds_acceptance'],
      null,
    ) as OddsAcceptance | null,
);

export const oddsAcceptanceAtom = atom(
  (get) => {
    const v2 = get(remoteOddsAcceptanceAtom);
    if (v2) {
      return v2;
    }

    const v1 = get(userPrefAtom).anyOdds
      ? OddsAcceptance.ANY
      : OddsAcceptance.NONE;
    return v1;
  },
  async (get, set, next: OddsAcceptance) => {
    set(userSettingAtom, { key: 'odds_acceptance', value: next });
  },
);
