import { Box, BoxProps, Typography } from '@mui/material';

import React, { ReactNode } from 'react';
import ArrowDown from 'src/assets/icons/arrow-down-2.svg';
import { useStyles } from './styles';

export interface CasinoFilterProps extends BoxProps {
  label?: ReactNode;
  value?: ReactNode;

  open: boolean;
  onToggle: (next?: any) => any;
}

export const CasinoFilter = React.forwardRef<any, CasinoFilterProps>(
  function CasinoFilterInner(props, ref) {
    const { label, value, open, onToggle, ...rest } = props;
    const { classes, cx } = useStyles();

    return (
      <Box ref={ref} className={classes.root} {...rest}>
        {label && <Typography color="custom.content.2">{label}</Typography>}
        <div className={cx(classes.root, classes.clicker)} onClick={onToggle}>
          <span>{value}</span>
          <ArrowDown className={cx(classes.icon, open && classes.rotate)} />
        </div>
      </Box>
    );
  },
);
