import _ from 'lodash';
import { CasinoSort } from 'src/_pages/casino/casino-category/casino-sorter';
import { CasinoFeatures__serialize } from 'src/_pages/casino/casino-category/feature-filter/options';
import { request } from 'src/utils/request';
import { DisplayType } from 'src/utils/types/admin';
import {
  CasinoCategory,
  CasinoGame,
  CasinoProducer,
  CasinoSession,
  CreateDemoSession,
  CreateRealSession,
  GetGamesParams,
} from 'src/utils/types/casino';
import { MyBetCasino, MyBetRequest } from 'src/utils/types/my-bet-type';
import { BaseResponse, PaginationResponse } from 'src/utils/types/response';

export const getProducersUser = async (category: string) => {
  const { data } = await request.get<PaginationResponse<CasinoProducer>>(
    `/m-casino/game-producer/producer-user`,
    {
      params: {
        categoryConfig: category || undefined,
      },
    },
  );

  for (const x of data.data.items) {
    x.producerName = _.upperFirst(x.producerName);
  }

  return data.data;
};

const CasinoSort__serialize = (
  params: GetGamesParams,
): Partial<GetGamesParams> => {
  const { __sort, sort_by, order_by } = params;

  if (__sort === CasinoSort.AZ) {
    return { sort_by: 'title', order_by: 'ASC' };
  }
  if (__sort === CasinoSort.ZA) {
    return { sort_by: 'title', order_by: 'DESC' };
  }
  if (__sort === CasinoSort.POPULAR) {
    return {};
  }

  return { sort_by, order_by };
};
export const getGamesByCategory = async (params: GetGamesParams) => {
  const {
    page_index,
    page_size,
    sort_by,
    order_by,
    __features,
    __sort,
    ...rest
  } = params;

  const { data } = await request.get<PaginationResponse<CasinoGame>>(
    `/m-casino/game/game-user`,
    {
      params: {
        ...rest,
        ...CasinoSort__serialize(params),
        ...CasinoFeatures__serialize(params),
        page: page_index + 1,
        size: page_size,
        showHide: DisplayType.ACTIVE,
      },
    },
  );

  for (const x of data.data.items) {
    x.producerName = _.upperFirst(x.producerName);
    x.provider = _.upperFirst(x.provider);
  }

  return data.data;
};

export const getRecentlyGame = async () => {
  const { data } = await request.get<PaginationResponse<CasinoGame>>(
    '/m-casino/game/recently-bet',
  );

  return data.data;
};

export const getFavoriteGames = async () => {
  const { data } = await request.get<PaginationResponse<CasinoGame>>(
    '/m-casino/game/favorite',
    {
      params: {
        page: 1,
        size: -1,
        sort_by: 'priority',
      },
    },
  );

  return data.data.items;
};

export const createDemoSession = async (body: CreateDemoSession) => {
  const { data } = await request.post<BaseResponse<CasinoSession>>(
    `/m-casino/game/user/play/create-demo`,
    body,
  );

  return data.data;
};

export const createRealSession = async (body: CreateRealSession) => {
  const { data } = await request.post<BaseResponse<CasinoSession>>(
    `/m-casino/game/user/play/create-session`,
    body,
  );

  return data.data;
};

export const fetchOneGame = async (identifier: string) => {
  const { data } = await request.get<BaseResponse<CasinoGame>>(
    `/m-casino/game/detail`,
    { params: { identifier } },
  );

  return data.data;
};

export const getMyBetCasino = async (params: MyBetRequest) => {
  const { data } = await request.get<PaginationResponse<MyBetCasino>>(
    `/casino/get-my-bets`,
    {
      params,
    },
  );

  // @ts-ignore
  return data.data;
};

export const getActiveCategories = async () => {
  const { data } = await request.get<PaginationResponse<CasinoCategory>>(
    '/m-casino/game-category/category-show',
  );

  return data.data.items;
};

export interface ThemeOption {
  count: number;
  theme: string;
}

export const JOINER = ',';

export const HOLIDAYS_GROUP = [
  'easter',
  'christmas',
  'holidays',
  'st_patrick_day',
  'st_valentines_day',
].join(JOINER);

export const HORROR_GROUP = ['halloween', 'horrors'].join(JOINER);

export const EATS_SWEETS_GROUP = ['food', 'sweets'].join(JOINER);

export const PARTY_TIME_GROUP = ['girls', 'party'].join(JOINER);

export const getAllThemes = async (category: string) => {
  const { data } = await request.get<PaginationResponse<ThemeOption>>(
    '/m-casino/game/game-theme',
    {
      params: {
        category,
      },
    },
  );

  const groupValues = [
    ...HOLIDAYS_GROUP.split(JOINER),
    ...HORROR_GROUP.split(JOINER),
    ...EATS_SWEETS_GROUP.split(JOINER),
    ...PARTY_TIME_GROUP.split(JOINER),
  ];

  const others = data.data.items.filter(
    ({ theme }) => theme && !groupValues.includes(theme),
  );

  return _.chain([
    HOLIDAYS_GROUP,
    HORROR_GROUP,
    EATS_SWEETS_GROUP,
    PARTY_TIME_GROUP,
  ])
    .map((group) => {
      let count = 0;
      for (const x of data.data.items) {
        if (group.includes(x.theme)) {
          count += x.count;
        }
      }
      return { count, theme: group };
    })
    .concat(others)
    .filter((x) => x.count > 0)
    .orderBy([(o) => o.theme.toLocaleLowerCase()], ['asc'])
    .value() as ThemeOption[];
};
