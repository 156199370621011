import { EventCompetitors } from './event';
import { BaseOutcome } from './outcome';
import { ILanguageBet, RoomType, SportLatestBet } from './socket-type';
import { BetSource } from './sportsbook';

interface ILanguageMyBet extends EventCompetitors {
  marketOutcomes: {
    marketName: string;
    outcomeName: string;
    outcomeGroupName?: string;
  };
  name?: string;
  sport: {
    name: string;
  };
}
export interface BetGame extends EventCompetitors {
  _id: string;
  id: number | string;
  status: string;
  startTime: string | number;
  brFixtureId: string;
  placeOdd: string;
  activePlaceOdd: string;
  eventId?: string;
  ticketOutcomes: any;
  outcomeId: string;
  marketId: string;
  isOutRight: boolean;
  isPromotionCampaign: boolean;
  eventName: string;
  name?: string;
  tournament: { name: string };
  marketOutcomeId?: string;
  marketSpecifiers?: string | null;
  producerId?: string;
  ticketId?: string;
  languages?: {
    [key: string]: ILanguageBet;
  };
  marketOutcomes: Pick<
    BaseOutcome,
    'marketName' | 'outcomeGroupName' | 'outcomeName'
  >;
  sport: SportLatestBet;
}

export interface MyBet {
  id: string;
  status: string;
  stake: string;
  odds: string;
  type: string;
  createdAt: string;
  totalGames: number;
  betGame: BetGame;
  payout: string;
  roomId: string;
  roomType: RoomType;
  placeTotalOdds: string;
  listOutcomes?: ListOutcomes[];
}

interface ListOutcomes {
  _id: string;
  currentOdd: number;
  eventId: string;
  favorite: number;
  marketId: string;
  marketSpecifiers: string | null;
  marketStatus: number;
  outcomeActive: number;
  outcomeId: string;
  probabilities: string;
  producerId: string;
  source: BetSource;
  updatedAt: Date;
  marketName: string;
  outcomeGroupName: string;
  outcomeName: string;
  placeOdd: string;
  ticketOutcomeStatus: string;
}

export interface MyBetCasino {
  ticketId: string;
  createdAt: string;
  gameIdentifier: string;
  gameTitle?: string;
  gameCategory?: string;
  username: string;
  stake: string;
  multiplier: string;
  payout: string;
  roomId: string;
  isFreespin: boolean;
}

export interface MyBetRequest {
  page: number | string;
  size: number;
  status?: number;
  lang?: string;
}

export interface DialogBetRequest {
  page: number;
  size: number;
  ticketId: string;
  lang?: string;
}

export interface DialogBetData {
  items: BetGame[];
  meta: {
    userId: number;
    payout: string;
    totalOdds: string;
    placeTotalOdds: string;
    stake: string;
    type: string;
    status: string;
    round: any;
    roomId: string;
    roomType: RoomType;
    note: any;
    cancel_reason: any;
    fee: string;
    username: string;
    odds: string;
    createdAt: string;
    total_count: number;
    rank?: string;
  };
}

export interface DialogBetResponse {
  code: string;
  data: DialogBetData;
}

export enum MyBetStatus {
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
  WON = 'WON',
  WIN = 'WIN',
  LOST = 'LOST',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  VOID = 'VOID',
  LOST_VOID_1 = 'LOST_VOID_1',
  CREATED = 'CREATED',
  CASHOUT = 'CASHOUT',
  LOST_VOID_0_5 = 'LOST_VOID_0_5',
  WIN_VOID_0_5 = 'WIN_VOID_0_5',
}
