/* eslint-disable lingui/no-unlocalized-strings */
import _ from 'lodash';
import { getGameThumbnail } from 'src/utils/helpers/casino';
import { brStatusToFixtureStatus, isOddin } from 'src/utils/helpers/fixture';
import { IUpdateAccountLevel, ProfileData } from 'src/utils/types/auth';
import { CasinoGame } from 'src/utils/types/casino';
import { BaseEvent, DetailEventInterface } from 'src/utils/types/event';
import { BetSlipItemInterface } from 'src/utils/types/sportsbook';

import { MySport } from 'src/utils/types/sport';
import { Observable } from './etc';

type Rudder = typeof import('rudder-sdk-js');

const rudder = new Observable<Rudder>();
let loading = false;

const getRudderstack = async (): Promise<Rudder | undefined> => {
  let current = rudder.current();
  if (current) return Promise.resolve(current);

  if (loading) {
    return new Promise<Rudder>((resolve) => {
      rudder.subscribe((n) => {
        resolve(n);
      });
    });
  }

  loading = true;
  const sdk = await import('rudder-sdk-js');
  current = await new Promise<Rudder>((resolve) => {
    sdk.load(
      process.env.NEXT_PUBLIC_WRITE_KEY ?? '',
      process.env.NEXT_PUBLIC_DATA_PLANE_URL ?? '',
      { integrations: { All: true } },
    );
    sdk.ready(() => {
      resolve(sdk);
    });
  });
  loading = false;
  rudder.next(current);

  return current;
};

function withRudderstack<
  InputFn extends (...args: any[]) => any,
  Args extends Parameters<InputFn>,
>(fn: (r: Rudder, ...arg: Args) => any) {
  return async (...args: Args) => {
    const r = await getRudderstack();
    if (!r) return;

    return fn(r, ...args);
  };
}

export const trackPageView = withRudderstack((r) => {
  const [title] = document.title.split(' | ');
  r.page(title);
});

export const trackLogout = withRudderstack((r, userId: number | string) => {
  r.track('Logout', { userId: String(userId) });
  r.reset(true);
});

export const trackBetButtonClicked = withRudderstack(
  async (r, userId?: number, bs?: BetSlipItemInterface) => {
    r.track('Bet Button Clicked', {
      userId: String(userId),
      sportId: bs?._rudderstack.sportId,
      tournamentId: bs?._rudderstack.tournamentId,
      eventId: bs?.eventId,
      marketId: bs?.marketId,
      outcomeId: bs?.outcomeId,
    });
  },
);

interface WalletPayload {
  userId?: number | string;
  currency?: string;
  network?: string;
}

export const trackWithdrawalInitiated = withRudderstack(
  async (r, payload: WalletPayload) => {
    r.track('Withdrawal Initiated', {
      ...payload,
      userId: String(payload.userId),
    });
  },
);

interface DepositPayload {
  userId?: number | undefined;
  anonymousId?: string;
  source: string;
}

export const trackDepositInitiated = withRudderstack(
  async (r, payload: DepositPayload) => {
    r.track('User Deposit Initiated', {
      ...payload,
      ...(payload.userId
        ? { userId: String(payload.userId) }
        : { anonymousId: r.getAnonymousId() }),
    });
  },
);

export const trackDepositViewAddress = withRudderstack(
  async (r, payload: WalletPayload) => {
    r.track('View Deposit Address', {
      ...payload,
      userId: String(payload.userId),
    });
  },
);

export const trackDepositCopyAddress = withRudderstack(
  async (r, payload: WalletPayload) => {
    r.track('Copy Deposit Address', {
      ...payload,
      userId: String(payload.userId),
    });
  },
);

export const trackRegisterInit = withRudderstack(async (r, source: string) => {
  r.track(`User Registration Initiated`, {
    anonymousId: r.getAnonymousId(),
    source,
  });
});

export const trackRegisterInfo = withRudderstack(
  async (r, source: { dateOfBirth: string }) => {
    r.track('User Registration Basic Info Submitted', {
      anonymousId: r.getAnonymousId(),
      source,
    });
  },
);

// const trackRegisterOTP = withRudderstack(async (r) => {
//   r.track('User Registration OTP Submitted', {
//     anonymousId: r.getAnonymousId(),
//   });
// });

export const trackLogin = withRudderstack(async (r) => {
  r.track('User Login', {});
});

// Rudderstack function for Match Page Event
export const trackMatchPage = withRudderstack(
  async (r, data: { event: DetailEventInterface; locale: string }) => {
    const { event, locale } = data;
    const marketId = '1';

    const template = event.templates.find((x) => x.marketId === marketId);
    if (!template) return;
    const market = template.markets.find((x) => x.marketId === marketId);
    if (!market) return;

    // 1x2 market always returns [1,2,3];
    // betradar has order 1,2,3
    // oddin has order 1,3,2
    const [homeOutcome, drawOutcome, awayOutcome] = market.outcomeGroups
      .flatMap((x) => x.outcomes)
      .toSorted((a, b) => +a - +b);

    // Extract match_id number from eventId
    const matchId = event?.eventId?.split(':').pop() || '';

    // Tracking logic
    r.track('Match Page Viewed', {
      sportsEvent: {
        match_id: matchId,
        team_home_name: event?.competitor1,
        team_away_name: event?.competitor2,
        scheduled: event?.startTime,
        tournament_name: event?.tournament?.name,
        sport: event.sport?.name.toLowerCase(),
        odds_home: homeOutcome?.currentOdd?.toString(),
        odds_draw: drawOutcome?.currentOdd?.toString() || '',
        odds_away: awayOutcome?.currentOdd?.toString(),
        language: locale,
        sr_match_id: event?.eventId || '',
        team_home_name_short: '',
        team_away_name_short: '',
        team_home_logo: '',
        team_away_logo: '',
        status: '',
      },
    });
  },
);

// Rudderstack function for Casino Game Page Event
export const trackCasinoGamePage = withRudderstack(
  async (r, gameDetail: CasinoGame) => {
    r.track('Casino Game Page Viewed', {
      casinoEvent: {
        game: {
          id: gameDetail.identifier,
          name: gameDetail.title,
          category: gameDetail.categoryConfig,
          image: getGameThumbnail(gameDetail.identifier),
          provider: {
            id: '',
            game_id: '',
          },
        },
      },
    });
  },
);

// Rudderstack function for Casino Game Launch Event
export const trackCasinoGameLaunch = withRudderstack(
  async (r, gameDetail: CasinoGame) => {
    r.track('Casino Game Launched', {
      casinoEvent: {
        game: {
          id: gameDetail.identifier,
          name: gameDetail.title,
          category: gameDetail.categoryConfig,
          image: getGameThumbnail(gameDetail.identifier),
          provider: {
            id: '',
            game_id: '',
          },
        },
      },
    });
  },
);

// Rudderstack function for Bet Slip Event
export const trackBetSlip = withRudderstack(
  async (
    r,
    data: {
      event: BaseEvent | DetailEventInterface;
      sport?: MySport;
      locale: string;
    },
  ) => {
    const { event, sport, locale } = data;

    // This prevents events triggered from Match List Page from being tracked.
    // And also prevents Oddin events from being tracked.
    if (!('templates' in event) || isOddin(event.eventId)) {
      return;
    }

    // Target Market ID
    const marketId = '1';

    // Retrieve outcomes from event templates
    const outcomes =
      event.templates
        .find((template) => template.marketId === marketId)
        ?.markets.find((x) => x.marketId === marketId)
        ?.outcomeGroups.flatMap((x) => x.outcomes) || [];

    // Return if no outcomes found
    if (_.isEmpty(outcomes)) return;

    // Find specific outcomes based on outcomeId
    const homeOutcome = outcomes.find((x) => x?.outcomeId === '1');
    const drawOutcome = outcomes.find((x) => x?.outcomeId === '3');
    const awayOutcome = outcomes.find((x) => x?.outcomeId === '2');

    // Extract match_id number from eventId
    const matchId = _.last(event?.eventId?.split(':'));

    // Tracking logic
    r.track('Bet Slip Match Added', {
      match_id: matchId,
      team_home_name: event.competitor1,
      team_away_name: event.competitor2,
      scheduled: event.startTime,
      tournament_name: event.tournament?.name,
      sport: (sport?.name ?? event.sport?.name)?.toLowerCase(),
      odds_home: homeOutcome?.currentOdd?.toString(),
      odds_draw: drawOutcome?.currentOdd?.toString() || '',
      odds_away: awayOutcome?.currentOdd?.toString(),
      language: locale,
      sr_match_id: event.eventId || '',
      team_home_name_short: '',
      team_away_name_short: '',
      team_home_logo: '',
      team_away_logo: '',
      status: _.chain(brStatusToFixtureStatus)
        .invert()
        .get([event.status], '')
        .startCase()
        .toLower()
        .value(),
    });
  },
);

// const trackRegisterCompleted = withRudderstack(async (r, userId: number) => {
//   const newId = String(userId);
//   const anonymousId = r.getAnonymousId();

//   r.alias(newId, anonymousId, undefined, () => {
//     r.track('User Registration Completed', {
//       anonymousId,
//       userId: newId,
//     });
//   });
// });

export const trackIdentify = withRudderstack(
  async (
    r,
    data: {
      profile?: ProfileData | null;
      accountInfo?: IUpdateAccountLevel | null;
      locale?: string;
    },
  ) => {
    const { locale, profile, accountInfo } = data;

    if (!profile || profile instanceof Error) {
      r.identify();
      return;
    }
    let other = {};

    if (accountInfo && !(accountInfo instanceof Error)) {
      const isFalsy = (v: any) => typeof v !== 'number' && _.isEmpty(v);
      other = _.omitBy(
        {
          first_name: accountInfo.firstName,
          last_name: accountInfo.lastName,
          date_of_birth: accountInfo.dateOfBirth,
          address: _.omitBy(
            {
              city: accountInfo.city,
              country: accountInfo.country,
              state: accountInfo.state,
              street: accountInfo.address,
            },
            isFalsy,
          ),
        },
        isFalsy,
      );
    }

    r.identify(String(profile.id), {
      username: profile.username,
      email: profile.email,
      locale,
      ...other,
    });
  },
);
