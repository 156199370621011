import { request } from 'src/utils/request';
import { BaseResponse } from 'src/utils/types/response';
import { MySport, MyTournament, SportTypeEnum } from 'src/utils/types/sport';

export const toggleFavCasino = async (identifier: string | number) => {
  const { data } = await request.post<BaseResponse>(
    `/user-favorite/set-favorite`,
    { id: identifier, type: 'game' },
  );

  return data.code === '00';
};

export const toggleFavSport = async (sport_id: string) => {
  const { data } = await request.post<BaseResponse>(
    `/user-favorite/set-favorite`,
    { id: sport_id, type: 'sport' },
  );

  return data.code === '00';
};

export const toggleFavTournament = async (tournamentId: string | undefined) => {
  const { data } = await request.post<BaseResponse>(
    `/user-favorite/set-favorite`,
    { id: tournamentId, type: 'tournament' },
  );

  return data.code === '00';
};

export interface AllSports {
  eSport: MySport[];
  sport: MySport[];
}

export interface AllTournaments {
  eSport: MyTournament[];
  sport: MyTournament[];
}

const injectSportType = (data: AllSports | AllTournaments) => {
  data.sport.forEach((x) => {
    x.__sportType = SportTypeEnum.Sport;
  });

  data.eSport.forEach((x) => {
    x.__sportType = SportTypeEnum.ESport;
  });

  return data;
};

export const getAllSports = async (lang: string) => {
  const { data } = await request.get<BaseResponse<AllSports>>(
    '/sport/user/list',
    { params: { lang } },
  );

  injectSportType(data.data);
  return data.data;
};

export const getAllTournaments = async (lang: string) => {
  const { data } = await request.get<BaseResponse<AllTournaments>>(
    '/tournament/user/list',
    { params: { lang } },
  );

  injectSportType(data.data);
  return data.data;
};

export const getFavSports = async (lang: string) => {
  const { data } = await request.get<BaseResponse<AllSports>>(
    '/sport/favorite',
    { params: { lang } },
  );

  injectSportType(data.data);
  return data.data;
};

export const getFavTournaments = async (lang: string) => {
  const { data } = await request.get<BaseResponse<AllTournaments>>(
    '/tournament/favorite',
    { params: { lang } },
  );

  injectSportType(data.data);
  return data.data;
};
