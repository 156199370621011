import { RoleType } from '../types/enums/auth-status';

export const isAnyAdmin = (role: any) => {
  if (!role) return false;
  const r = Number(role);
  if (Number.isNaN(r)) return false;

  if (![RoleType.Admin, RoleType.SuperAdmin].includes(r)) {
    return false;
  }

  return true;
};
