import _ from 'lodash';
import { TICKET_CODES } from 'src/utils/constants/bet-constant';
import { BaseOutcome } from 'src/utils/types/outcome';
import { MyBetMessage } from 'src/utils/types/socket-type';
import { BetSource } from 'src/utils/types/sportsbook';

const prefixMaps = {
  [BetSource.BET_RADAR]: 'mts',
  [BetSource.ODDIN]: 'ots',
  [BetSource.CAMPAIGN]: 'ubet',
};

export const generateAlias = (
  outcome: Pick<BaseOutcome, '_id' | 'eventId' | 'source'>,
) => {
  const hash = simpleHash(outcome._id + '_' + Date.now());
  const prefix = prefixMaps[outcome.source];

  return `${prefix}_${hash}`;
};

const simpleHash = (s: string) => {
  let a = 1,
    c = 0,
    h,
    o;
  if (s) {
    a = 0;
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = c !== 0 ? a ^ (c >> 21) : a;
    }
  }
  return a;
};

function matched(targets: number[], codes: number[]) {
  for (const a of targets) {
    for (const b of codes) {
      if (a === b) return true;
    }
  }

  return false;
}

export const checkTicketError = (msg: MyBetMessage) => {
  if (!msg.error) return null;

  const { error } = msg;

  const codes = _.chain(error.detail).values().uniq().value();

  const res = _.mapValues(error.detail, (___, alias) => {
    const [key] = alias.match(/mts|ots/) ?? ['mts'];

    const { ALT_STAKE, MARKETS_CLOSED, ODDS_CHANGE, SUSPENDED } =
      TICKET_CODES[key as 'mts' | 'ots'];

    if (matched(SUSPENDED, codes)) return 'suspended';
    if (matched(MARKETS_CLOSED, codes)) return 'canceled';
    if (matched(ODDS_CHANGE, codes)) return 'canceled';
    if (msg.alternativeStake && matched(ALT_STAKE, codes)) return 'altStake';

    return null;
  }) as {
    [alias: string]:
      | 'oddsChange'
      | 'canceled'
      | 'altStake'
      | 'suspended'
      | null;
  };

  if (_.chain(res).omitBy(_.isEmpty).isEmpty().value()) return null;

  return res;
};
