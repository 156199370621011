import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    clicker: {
      fontWeight: 700,
      marginLeft: 8,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    icon: {
      marginLeft: 4,
      flexShrink: 0,
      transform: 'rotate(180deg)',
      transition: theme.transitions.create(['transform']),
    },
    rotate: {
      transform: 'rotate(0deg)',
    },
  };
});
