import { atom, SetStateAction } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import _ from 'lodash';
import { CampaignEvent } from 'src/api/campaign';
import { ErrorTypes } from 'src/components/modules/bet-slip-drawer/components/shared/error-message';
import { toFormat } from 'src/hooks/use-stake-coin';
import { BN } from 'src/utils/helpers/big-number';
import { campaignToBetslip } from 'src/utils/helpers/campaign';
import { isDeviceType, MyDevices } from 'src/utils/helpers/etc';
import { compareOutcome, isOddsChange } from 'src/utils/helpers/fixture';
import {
  trackBetButtonClicked,
  trackRegisterInit,
} from 'src/utils/helpers/rudderstack';
import {
  BetSlipItemInterface,
  BetSlipModeType,
  BsError,
} from 'src/utils/types/sportsbook';
import { loggedAtom, profileAtom, waitForLoggedAtom } from './auth';
import {
  fiatAtom,
  OddsAcceptance,
  stakeCoinBalanceAtom,
  stakeCoinPriceAtom,
} from './layout';
import { oddsAcceptanceAtom, remoteOddsAcceptanceAtom } from './settings';

const _multiStakeAtom = atom('');
export const multiStakeAtom = atom(
  (get) => get(_multiStakeAtom),
  (
    get,
    set,
    payload: Partial<{
      value: string;
      error: BsError;
      multiAltStakeError: boolean;
      multiPreTicketId: string;
      multiAltStake: string;
    }>,
  ) => {
    set(_multiStakeAtom, (prev) => payload.value ?? prev);
    set(multiStakeErrorAtom, (prev) =>
      payload.error === undefined ? prev : payload.error,
    );
    set(multiAltStakeErrorAtom, (prev) => payload.multiAltStakeError ?? prev);
    set(multiPreTicketIdAtom, (prev) => payload.multiPreTicketId ?? prev);
    set(multiAltStakeAtom, (prev) => payload.multiAltStake ?? prev);
  },
);

const _modeBetSlipAtom = atom(BetSlipModeType.Single);
export const modeBetSlipAtom = atom(
  (get) => get(_modeBetSlipAtom),
  (get, set, mode: SetStateAction<BetSlipModeType>) => {
    set(_modeBetSlipAtom, mode);
    set(_multiStakeAtom, '');
    return mode;
  },
);

export const loadingBetSlipAtom = atom(false);

export const openMoneyDropdownAtom = atom(false);

export enum RightDrawers {
  Betslip = 1,
  MyAccount = 2,
  MyMoney = 3,
}

const { subscribe, ...jsonStorage } = createJSONStorage<RightDrawers | null>();

export const rightDrawerAtom = atomWithStorage<RightDrawers | null>(
  'drawer',
  null,
  jsonStorage,
);

const toggle = (
  dispatch: SetStateAction<boolean>,
  val: RightDrawers,
): SetStateAction<RightDrawers | null> => {
  return (prev) => {
    const prevOpen = prev === val;
    const nextOpen =
      typeof dispatch === 'boolean' ? dispatch : dispatch(prevOpen);
    return nextOpen ? val : null;
  };
};

export const openBetSlipAtom = atom(
  (get) => get(loggedAtom) && get(rightDrawerAtom) === RightDrawers.Betslip,
  (get, set, next: SetStateAction<boolean>) => {
    set(rightDrawerAtom, toggle(next, RightDrawers.Betslip));
  },
);

export const myAccountAtom = atom(
  (get) => get(loggedAtom) && get(rightDrawerAtom) === RightDrawers.MyAccount,
  (get, set, next: SetStateAction<boolean>) => {
    set(rightDrawerAtom, toggle(next, RightDrawers.MyAccount));
  },
);

export const myMoneyAtom = atom(
  (get) => get(loggedAtom) && get(rightDrawerAtom) === RightDrawers.MyMoney,
  (get, set, next: SetStateAction<boolean>) => {
    set(rightDrawerAtom, toggle(next, RightDrawers.MyMoney));
  },
);
export const betSlipsAtom = atomWithStorage<BetSlipItemInterface[]>(
  'betSlips',
  [],
);

export const betslipsLengthAtom = atom((get) => get(betSlipsAtom).length);

export const clearAllBetSlipsAtom = atom(null, (get, set) => {
  set(betSlipsAtom, []);
  set(multiStakeAtom, {
    error: null,
    multiAltStake: '',
    multiAltStakeError: false,
    multiPreTicketId: '',
    value: '',
  });
  set(limitErrorAtom, null);
  set(kycErrorAtom, null);
  set(accountLevelErrorAtom, null);
});

export const removeBetSlipAtom = atom(
  null,
  (get, set, indexOrAlias: number | { alias: string }) => {
    set(betSlipsAtom, (prev) => {
      let index = -1;
      if (typeof indexOrAlias === 'object') {
        index = _.findIndex(prev, (o) => o.alias === indexOrAlias.alias);
      } else {
        index = indexOrAlias;
      }
      if (index === -1) return prev;
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  },
);

export const updateOneBetSlipAtom = atom(
  null,
  (
    get,
    set,
    indexOrAlias: number | { alias: string },
    data: Partial<BetSlipItemInterface>,
  ) => {
    set(betSlipsAtom, (prev) => {
      let index = -1;

      if (typeof indexOrAlias === 'object') {
        index = _.findIndex(prev, (o) => o.alias === indexOrAlias.alias);
      } else {
        index = indexOrAlias;
      }

      if (index === -1) return prev;

      if (data.currentOdd) {
        set(
          oddsChangeErrorAtom,
          (err) =>
            err ||
            isOddsChange({
              acceptance: get(remoteOddsAcceptanceAtom)
                ? get(oddsAcceptanceAtom)
                : OddsAcceptance.NONE,
              prev: prev[index].currentOdd,
              next: data.currentOdd ?? '',
            }),
        );
      }

      return [
        ...prev.slice(0, index),
        { ...prev[index], ...data },
        ...prev.slice(index + 1),
      ];
    });
  },
);

const _oddsChangeErrorAtom = atom(false);
export const isOddsChangeFirstTime = atom((get) => {
  return !get(remoteOddsAcceptanceAtom) && get(_oddsChangeErrorAtom);
});

export const oddsChangeErrorAtom = atom(
  (get) => {
    if (get(isOddsChangeFirstTime)) {
      return true;
    }

    if (get(oddsAcceptanceAtom) === OddsAcceptance.ANY) {
      return false;
    }

    return get(_oddsChangeErrorAtom);
  },
  (get, set, next: SetStateAction<boolean>) => {
    set(_oddsChangeErrorAtom, next);
  },
);

export const limitErrorAtom = atom<BsError>(null);
export const kycErrorAtom = atom<BsError>(null);
export const maxBetErrorAtom = atom<BsError>(null);
export const accountLevelErrorAtom = atom<BsError>(null);
export const multiStakeErrorAtom = atom<BsError>(null);
const multiAltStakeErrorAtom = atom(false);
export const multiPreTicketIdAtom = atom('');
export const multiAltStakeAtom = atom('');
export const openMultiBetAtom = atom(false);

export const xorBetslipsAtom = atom(
  null,
  async (get, set, items: BetSlipItemInterface[]) => {
    if (_.isEmpty(items)) return;

    let profile = get(profileAtom);
    if (!profile?.id) {
      trackRegisterInit('bet_button');
      profile = await set(waitForLoggedAtom, { register: true });
    }

    if (profile?.timeSelfExclude) {
      throw { code: ErrorTypes.SelfExclude };
    }

    set(betSlipsAtom, (prev) => {
      const updated = _.xorWith(prev, items, compareOutcome);
      if (updated.length > prev.length) {
        for (const bs of items) {
          trackBetButtonClicked(profile?.id, bs);
        }
      }

      const atLeast2 = updated.length >= 2;
      const firstTime = prev.length === 0 && updated.length >= 1;
      set(openBetSlipAtom, firstTime || isDeviceType(MyDevices.DESKTOP));

      set(modeBetSlipAtom, (prev) => {
        if (prev === BetSlipModeType.MyBets) {
          return BetSlipModeType.Single;
        }
        return prev;
      });

      set(openMultiBetAtom, atLeast2);

      return updated;
    });
  },
);

export const addCampaignAtom = atom(
  null,
  async (
    get,
    set,
    data: {
      event: CampaignEvent | CampaignEvent[];
      outcomeId: string | string[];
      desktop?: boolean;
    },
  ) => {
    const outcomeIds = _.flatten([data.outcomeId]);
    const bs = _.chain([data.event])
      .flatten()
      .flatMap((event) =>
        outcomeIds.map((outcomeId) => campaignToBetslip({ event, outcomeId })),
      )
      .compact()
      .value();

    if (_.isEmpty(bs)) return;

    await set(xorBetslipsAtom, bs);
  },
);

export const isSingleAtom = atom(
  (get) => get(modeBetSlipAtom) === BetSlipModeType.Single,
);

export const isMultiAtom = atom(
  (get) => get(modeBetSlipAtom) === BetSlipModeType.Multi,
);

export const totalStakeAtom = atom((get) => {
  if (get(isSingleAtom)) {
    const items = get(betSlipsAtom);

    return items.reduce(
      (prev, cur) => prev.plus(cur.singleStake || 0),
      new BN(0),
    );
  }

  return new BN(get(multiStakeAtom) || 0);
});

export const totalOddsAtom = atom((get) => {
  if (get(isSingleAtom)) {
    return new BN(0);
  }

  const items = get(betSlipsAtom);
  return items.reduce(
    (prev, cur) => prev.multipliedBy(cur.currentOdd),
    new BN(_.isEmpty(items) ? 0 : 1),
  );
});

export const totalPayoutAtom = atom((get) => {
  if (get(isSingleAtom)) {
    return get(betSlipsAtom).reduce(
      (prev, cur) =>
        prev.plus(new BN(cur.singleStake || 0).multipliedBy(cur.currentOdd)),
      new BN(0),
    );
  }

  return get(totalOddsAtom)
    .dp(2)
    .multipliedBy(get(multiStakeAtom) || 0);
});

export const hasCanceledAtom = atom(
  (get) => {
    return _.some(get(betSlipsAtom), (o) => o.canceledError);
  },
  (get, set) => {
    set(betSlipsAtom, (prev) => prev.filter((x) => !x.canceledError));
  },
);

const hasSingleErrorAtom = atom((get) => {
  return _.some(get(betSlipsAtom), (o) => o.singleStakeError);
});

export const hasSameEventErrorAtom = atom((get) => {
  return _.some(get(betSlipsAtom), (o) => o.sameFixtureError);
});

const hasErrorAtom = atom(
  (get) => {
    return get(isSingleAtom)
      ? get(hasSingleErrorAtom)
      : get(hasSameEventErrorAtom);
  },
  (get, set) => {
    set(limitErrorAtom, null);
    set(kycErrorAtom, null);
    set(hasCanceledAtom);
  },
);

export const hasCrossErrorAtom = atom((get) => {
  return _.some(get(betSlipsAtom), (o) => o.crossBetError);
});

export const hasAltStakeAtom = atom(
  (get) => {
    const isSingle = get(isSingleAtom);
    if (isSingle) {
      return _.some(get(betSlipsAtom), (o) => o.altStakeError);
    }

    return get(multiAltStakeErrorAtom);
  },
  (get, set) => {
    set(betSlipsAtom, (prev) =>
      prev.map((o) => ({ ...o, altStakeError: false })),
    );
    set(multiStakeAtom, { multiAltStakeError: false });
  },
);

export const availableBalanceAtom = atom((get) => {
  const balance = get(stakeCoinBalanceAtom);
  const price = get(stakeCoinPriceAtom);
  const fiat = get(fiatAtom);

  return toFormat(balance?.balance_available ?? '0', fiat, price);
});

const insufficientAtom = atom((get) => {
  return new BN(get(availableBalanceAtom)).lt(get(totalStakeAtom));
});

export const balanceErrorAtom = atom((get) => {
  return (
    get(limitErrorAtom) || (!get(loadingBetSlipAtom) && get(insufficientAtom))
  );
});

export const anyErrorAtom = atom((get): BsError => {
  const isSingle = get(isSingleAtom);

  const accountLevelError = get(accountLevelErrorAtom);
  if (accountLevelError) return accountLevelError;

  const kycError = get(kycErrorAtom);
  if (kycError) return kycError;

  const limitError = get(limitErrorAtom);
  if (limitError) return limitError;

  const maxBetError = get(maxBetErrorAtom);
  if (maxBetError) return maxBetError;

  if (!isSingle && get(hasCrossErrorAtom)) {
    return { code: ErrorTypes.CrossBet };
  }

  if (get(hasErrorAtom)) {
    return { code: isSingle ? ErrorTypes.SomeErrors : ErrorTypes.SameFixture };
  }
  if (get(hasCanceledAtom)) return { code: ErrorTypes.LockedOrCancel };
  if (get(oddsChangeErrorAtom)) return { code: ErrorTypes.OddsChange }; // && OddsChangeError;
  if (get(insufficientAtom)) return { code: ErrorTypes.Insufficient }; // && Insufficient;
  if (get(hasAltStakeAtom)) return { code: ErrorTypes.HasAltStake };

  return null;
});
