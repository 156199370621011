'use client';

import { Trans } from '@lingui/macro';
import { custom, primary } from 'src/theme';
import { MyBetStatus } from '../types/my-bet-type';

export const BET_STATUS_STYLES: Record<
  string,
  { color: string; background: string }
> = {
  [MyBetStatus.LIVE]: {
    color: custom.status.green,
    background: custom.bg[3],
  },
  [MyBetStatus.UPCOMING]: {
    color: custom.content[3],
    background: custom.bg[3],
  },
  [MyBetStatus.CREATED]: {
    color: custom.content[3],
    background: custom.bg[3],
  },
  [MyBetStatus.WON]: {
    color: custom.content[1],
    background: custom.status.green,
  },
  [MyBetStatus.WIN]: {
    color: custom.content[1],
    background: custom.status.green,
  },
  [MyBetStatus.LOST]: {
    color: custom.content[1],
    background: custom.status.red,
  },
  [MyBetStatus.CANCELED]: {
    color: custom.content[2],
    background: custom.content[3],
  },
  [MyBetStatus.VOID]: {
    color: custom.content[2],
    background: custom.content[3],
  },
  [MyBetStatus.REJECTED]: {
    color: custom.status.red,
    background: '#3C1E1E',
  },
  [MyBetStatus.LOST_VOID_1]: {
    color: custom.content[1],
    background: custom.status.red,
  },
  [MyBetStatus.WIN_VOID_0_5]: {
    color: custom.content[1],
    background: custom.status.green,
  },
  [MyBetStatus.LOST_VOID_0_5]: {
    color: custom.content[1],
    background: custom.status.red,
  },
  [MyBetStatus.CASHOUT]: {
    color: custom.content[1],
    background: custom.status.green,
  },
};

export const BET_PAYOUT: { [key: string]: string } = {
  [MyBetStatus.WON]: custom.status.green,
  [MyBetStatus.LOST]: custom.status.red,
  default: primary.main,
};

export const getBetName = () => ({
  [MyBetStatus.LIVE]: <Trans id="ticket.status.live">LIVE</Trans>,
  [MyBetStatus.UPCOMING]: <Trans id="ticket.status.upcoming">UPCOMING</Trans>,
  [MyBetStatus.WON]: <Trans id="ticket.status.won">WON</Trans>,
  [MyBetStatus.WIN]: <Trans id="ticket.status.won">WON</Trans>,
  [MyBetStatus.LOST]: <Trans id="ticket.status.lost">LOST</Trans>,
  [MyBetStatus.CANCELED]: <Trans id="ticket.status.canceled">CANCELED</Trans>,
  [MyBetStatus.VOID]: <Trans id="ticket.status.void">VOID</Trans>,
  [MyBetStatus.LOST_VOID_1]: <Trans id="ticket.status.void">VOID</Trans>,
  [MyBetStatus.CREATED]: <Trans id="ticket.status.created">CREATED</Trans>,
  [MyBetStatus.CASHOUT]: (
    <Trans id="ticket.status.cashed_out">CASHED OUT</Trans>
  ),
  [MyBetStatus.REJECTED]: <Trans id="ticket.status.rejected">REJECTED</Trans>,
  [MyBetStatus.LOST_VOID_0_5]: (
    <Trans id="ticket.status.lost_void_0_5">HALF LOST</Trans>
  ),
  [MyBetStatus.WIN_VOID_0_5]: (
    <Trans id="ticket.status.win_void_0_5">HALF WON</Trans>
  ),
});

export const MAX_BET = 63;

export const TICKET_CODES = {
  mts: {
    MARKETS_CLOSED: [-205, -207, -320, -321, -322, -505, -506] as number[],
    ODDS_CHANGE: [-316, -420, -421, -430, -431, -432, -441, -442] as number[],
    ALT_STAKE: [
      -306, -308, -511, -512, -513, -701, -702, -703, -711, -712, -713, -721,
      -722, -723,
    ] as number[],
    SUSPENDED: [
      -401, -402, -403, -404, -405, -406, -407, -408, -422, -423,
    ] as number[],
  },
  ots: {
    MARKETS_CLOSED: [] as number[],
    ODDS_CHANGE: [1] as number[], //[{ parent: 8, children: [1] }],
    ALT_STAKE: [4, 5, 7] as number[], //[{ parent: 7, children: [4, 5, 7] }],
    SUSPENDED: [7, 8, 2] as number[], //[{ parent: 8, children: [2] }], [{ parent: 7, children: [2] }],
  },
} as const;
