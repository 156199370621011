import _ from 'lodash';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { UserFingerprint } from 'src/store/auth';
import {
  INITIAL_USER_PREF,
  UserPrefer,
} from 'src/utils/constants/common-constant';
import {
  USER_FINGERPRINT,
  USER_JWT_KEY,
  USER_PREF_KEY,
} from 'src/utils/constants/keys';

export const clientCookie = {
  get: (key: string) => {
    try {
      const c = parseCookies(null);
      const val = _.get(c, [key]);

      return val as string;
    } catch (err) {
      return undefined;
    }
  },
  set: (key: string, val: string, opts?: any) => {
    try {
      setCookie(null, key, val, { ...opts, path: '/' });

      return true;
    } catch (err) {
      return false;
    }
  },
  destroy: (key: string) => {
    try {
      destroyCookie(null, key, { path: '/' });

      return true;
    } catch (err) {
      return false;
    }
  },
};

export const clientJWT = () => {
  return clientCookie.get(USER_JWT_KEY) ?? null;
};

export const clientFp = () => {
  const json = clientCookie.get(USER_FINGERPRINT) ?? 'null';

  try {
    return JSON.parse(json) as UserFingerprint;
  } catch (err) {
    return null;
  }
};

export const clientUserPref = () => {
  try {
    const json = clientCookie.get(USER_PREF_KEY) ?? 'null';

    const raw = JSON.parse(json) as UserPrefer | null;
    if (!raw) return INITIAL_USER_PREF;

    return { ...INITIAL_USER_PREF, ...raw };
  } catch (err) {
    return INITIAL_USER_PREF;
  }
};
