'use client';

import { Trans } from '@lingui/macro';
import { IconButton, List, MenuItem, Popover } from '@mui/material';
import _ from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { CasinoFilter } from 'src/_pages/casino/casino-category/casino-filter';
import DoubleArrowIcon from 'src/assets/icons/double-arrow.svg';
import { useStyles } from './styles';

export enum CasinoSort {
  POPULAR = 'popular',
  AZ = 'a-z',
  ZA = 'z-a',
}

const getOpts = () => [
  {
    label: <Trans id="popular">Popular</Trans>,
    value: CasinoSort.POPULAR,
  },
  {
    label: <Trans id="casino.sort_by.a_z">A-Z</Trans>,
    value: CasinoSort.AZ,
  },
  {
    label: <Trans id="casino.sort_by.z_a">Z-A</Trans>,
    value: CasinoSort.ZA,
  },
];

interface CasinoSorterProps {
  value?: CasinoSort;
  onChange?: (next: CasinoSort) => void;
  icon?: boolean;
}

export const CasinoSorter: React.FCC<CasinoSorterProps> = (props) => {
  const { value, onChange, icon = false } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { classes } = useStyles();
  const opts = getOpts();
  const selected = opts.find((x) => x.value === value) ?? _.first(opts);

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      {icon ? (
        <IconButton
          size="large"
          sx={{ my: -1.5, mx: -1, px: 1 }}
          ref={anchorRef}
          onClick={() => setOpen(true)}>
          <DoubleArrowIcon width={24} />
        </IconButton>
      ) : (
        <CasinoFilter
          ref={anchorRef}
          label={<Trans id="casino.sort_by">Sort By:</Trans>}
          value={selected?.label}
          open={open}
          onToggle={() => setOpen(true)}
        />
      )}

      {open && (
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          componentsProps={{
            backdrop: {
              invisible: true,
              onTouchMove: handleClose,
              className: classes.backdrop,
            },
          }}
          slotProps={{ paper: { className: classes.popoverPaper } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: icon ? 'left' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: icon ? 'left' : 'right',
          }}
          onClose={handleClose}>
          <List>
            {opts.map((obj) => {
              const isSelected = selected?.value === obj.value;

              return (
                <MenuItem
                  selected={isSelected}
                  onClick={() => {
                    onChange?.(obj.value);
                    handleClose();
                  }}
                  className={classes.item}
                  key={obj.value}>
                  {obj.label}
                </MenuItem>
              );
            })}
          </List>
        </Popover>
      )}
    </>
  );
};
