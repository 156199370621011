import { atom } from 'jotai';
import _ from 'lodash';
import { getActiveCategories, getFavoriteGames } from 'src/api/casino';
import { toggleFavCasino } from 'src/api/left-nav';
import { CasinoCategory, CasinoGame } from 'src/utils/types/casino';

export const _categoriesAtom = atom<CasinoCategory[]>([]);
export const _favGamesAtom = atom<CasinoGame[]>([]);

export const categoriesAtom = atom(
  (get) => get(_categoriesAtom),
  async (get, set) => {
    const payload = await getActiveCategories();
    set(_categoriesAtom, payload);
    return payload;
  },
);

export const favGamesAtom = atom(
  (get) => get(_favGamesAtom),
  async (get, set, game?: CasinoGame) => {
    if (game) {
      toggleFavCasino(game._id);
      set(_favGamesAtom, (prev) => {
        return _.chain(prev)
          .xorWith([game], (a, b) => a._id === b._id)
          .sortBy('priority')
          .value();
      });
    }

    const data = await getFavoriteGames();

    set(_favGamesAtom, data);
  },
);

export const clearCasinoFavAtom = atom(null, (get, set) => {
  set(_favGamesAtom, []);
});
