import {
  EMAIL_DOMAIN,
  IGNORE_UNAUTHORIZED_HEADER,
} from 'src/utils/constants/keys';
import { getResponseMsg } from 'src/utils/constants/response-code';
import { request } from 'src/utils/request';
import {
  AuthResponse,
  EnableSelfExcludeResponse,
  ForgotPasswordForm,
  GetAccountInfoResponse,
  GetProfileResponse,
  ISetPrivateProfile,
  IUpdateAccountLevel,
  KYCStatusKey,
  LoginForm,
  LoginResponse,
  PromoCodeResponse,
  ResetPasswordForm,
  UserRegisterForm,
} from 'src/utils/types/auth';
import { BaseResponse } from 'src/utils/types/response';
import {
  BooleanResponse,
  DataChangePassword,
  DataConfirmChangePassword,
  Enable2FABody,
  GenerateSecretResponse,
  Remove2FABody,
  SetLimitDeposit,
} from 'src/utils/types/setting';

export const checkExistedAccount = async (body: {
  username?: string;
  email?: string;
  recaptcha: string;
  user_id?: number;
}) => {
  const { username, email, recaptcha, user_id } = body;

  const res = await request.post(
    '/auth/check-existed-account',
    { username, email, user_id },
    { headers: { recaptcha } },
  );
  const { email_existed, username_existed } = res.data.data;
  if (username_existed) {
    throw { code: '12', message: getResponseMsg('12'), data: res.data.data };
  }
  if (email_existed) {
    throw { code: '11', message: getResponseMsg('11'), data: res.data.data };
  }
  return res.data;
};

export const checkExistedPromoCode = async (promoCode: String | undefined) => {
  const res = await request.post<PromoCodeResponse>(
    '/auth/check-existed-promo-code',
    {
      promo_code: promoCode,
    },
  );

  if (!res.data.data?.promo_code_existed) {
    const code = '32';
    throw { code, message: getResponseMsg(code) };
  }
  return res.data;
};

export const register = async (data: UserRegisterForm) => {
  const { recaptcha, ...rest } = data;
  const response = await request.post<AuthResponse>(
    '/auth/signup',
    {
      ...rest,
    },
    { headers: { recaptcha } },
  );
  return response.data;
};

export type typeOtp = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export const requestEmailOtp = async (body: {
  email: string;
  username: string;
  type: typeOtp;
}) => {
  const { data } = await request.post<BaseResponse>(
    '/auth/user-request-verify-email',
    { ...body, domain: EMAIL_DOMAIN },
  );
  return data;
};

export const login = async (data: LoginForm) => {
  const { recaptcha, email, password, domain } = data;

  const response = await request.post<LoginResponse>(
    '/auth/signin',
    {
      email,
      password,
      domain,
    },
    { headers: { recaptcha } },
  );

  return response.data;
};
// export const protectLogin = async (key: string) => {
//   const response = await request.post<
//     BaseResponse<{ protected_key_valid: boolean }>
//   >('/auth/check-protected-key', { key });
//   return response.data.data.protected_key_valid;
// };

export const logout = async (token: string | null) => {
  if (!token) {
    return;
  }

  const response = await request.get('/auth/logout', {
    headers: {
      Authorization: `Bearer ${token}`,
      [IGNORE_UNAUTHORIZED_HEADER]: '1',
    },
  });
  return response.data;
};

type ForgotPassword = (data: ForgotPasswordForm) => Promise<BaseResponse>;
export const forgotPassword: ForgotPassword = async (data) => {
  const { email, recaptcha } = data;
  const res = await request.post(
    '/auth/forgot-password',
    { email, domain: EMAIL_DOMAIN },
    { headers: { recaptcha } },
  );
  return res.data;
};

type ResetPassword = (data: ResetPasswordForm) => Promise<BaseResponse>;
export const resetPassword: ResetPassword = async (data) => {
  const { new_password, confirm_password, token } = data;
  const res = await request.post(
    '/auth/reset-password',
    {
      new_password,
      confirm_password,
      domain: EMAIL_DOMAIN,
    },
    { params: { token } },
  );
  return res.data;
};

export const getProfile = async () => {
  const { data } = await request.get<GetProfileResponse>('/auth/profile');

  return data.data;
};

export const getAccountInfo = async () => {
  const { data } = await request.get<GetAccountInfoResponse>(
    '/auth/get-account-level-profile',
  );

  return data.data;
};

type ChangePassword = (data: DataChangePassword) => Promise<BaseResponse>;
export const changePassword: ChangePassword = async (data) => {
  const res = await request.post('/auth/change-password', data);
  return res.data;
};

type ConfirmChangePassword = (
  data: DataConfirmChangePassword,
) => Promise<BaseResponse>;
export const confirmChangePassword: ConfirmChangePassword = async (data) => {
  const res = await request.post('/auth/confirm-change-password/', data);
  return res.data;
};

export const setChangeMail = async ({
  recaptcha,
  ...body
}: {
  email: string;
  otp: string;
  verify_type: number;
  recaptcha?: string;
}) => {
  const { data } = await request.post<BaseResponse>(
    '/auth/change-email',
    body,
    { headers: { recaptcha } },
  );
  return data;
};

export const setConfirmChangeMail = async ({
  recaptcha,
  ...body
}: {
  recaptcha?: string;
  otp: string;
}) => {
  const { data } = await request.post<BaseResponse>(
    '/auth/confirm-change-email',
    body,
    { headers: { recaptcha } },
  );
  return data;
};

export const changePhoneNumber = async (phoneNumber: string) => {
  const { data } = await request.post<BaseResponse>('/auth/phone-number', {
    phone_number: phoneNumber,
  });
  return data;
};

export const enable2FA = async (body: Enable2FABody) => {
  const {
    data: { data },
  } = await request.post<BooleanResponse>('/auth/enable-2fa', body);

  return data;
};

export const remove2FA = async (body: Remove2FABody) => {
  const {
    data: { data },
  } = await request.post<BooleanResponse>('/auth/remove-2fa', body);

  return data;
};

export const generateSecret = async () => {
  const { data } = await request.post<GenerateSecretResponse>(
    '/auth/generate-secret',
  );

  return data.data;
};

export const checkKYCStatus = async () => {
  const { data } = await request.post<
    BaseResponse<{ kyc_status: KYCStatusKey }>
  >('/user/check-kyc-status');
  return data.data.kyc_status;
};

export const generateOnfidoToken = async () => {
  const { data } =
    await request.post<BaseResponse<{ sdk_token: string }>>('/kyc/gen-token');
  return data.data.sdk_token;
};

export const checkKYC = async () => {
  const { data } = await request.post<any>('/kyc/checks');

  return data;
};

export const setLimitDeposit = async (body: SetLimitDeposit) => {
  const response = await request.post<EnableSelfExcludeResponse>(
    '/user/limit-config',
    body,
  );
  return response.data;
};
export const enableSelfExclude = async () => {
  const response =
    await request.post<EnableSelfExcludeResponse>('/user/self-exclude');
  return response.data;
};

export const updateAccountLevel = async (body: IUpdateAccountLevel) => {
  const { data } = await request.post<BaseResponse>(
    '/auth/update-account-level-profile',
    body,
  );
  return data;
};

export const setPrivateProfile = async (body: ISetPrivateProfile) => {
  const res = await request.post<any>('/user/set-private-profile', body);

  return res.data;
};

export const setChangeUsername = async (body: { new_username: string }) => {
  const { data } = await request.post<BaseResponse>(
    '/auth/change-username',
    body,
  );

  return data;
};

export const resendVerifyEmail = async () => {
  const { data } = await request.post<
    BaseResponse<{ emailVerifyExpireAt: number }>
  >('/auth/resend-verify-email-request');

  return data.data.emailVerifyExpireAt;
};

export interface ActivationEmail {
  email: string;
  token: string;
}

export const activationEmail = async (body: ActivationEmail) => {
  const { data } = await request.post<BaseResponse>('/user/activation', body);

  return data;
};

export const setDefaultCoin = async (coin_symbol: string) => {
  const { data } = await request.post<BaseResponse>('/user/set-default-coin', {
    coin_symbol,
  });

  return data;
};
