import { atom } from 'jotai';
import _ from 'lodash';
import {
  AllSports,
  AllTournaments,
  getAllSports,
  getAllTournaments,
  getFavSports,
  getFavTournaments,
  toggleFavSport,
  toggleFavTournament,
} from 'src/api/left-nav';
import { localeAtom } from './auth';

export const _sportAtom = atom<{
  sports: AllSports;
  tournaments: AllTournaments;
  favSports: AllSports;
  favTournaments: AllTournaments;
}>({
  sports: { sport: [], eSport: [] },
  tournaments: { sport: [], eSport: [] },

  favSports: { sport: [], eSport: [] },
  favTournaments: { sport: [], eSport: [] },
});

export const sportAtom = atom(
  (get) => get(_sportAtom).sports,
  async (get, set) => {
    const lang = get(localeAtom);
    const sports = await getAllSports(lang);
    set(_sportAtom, (prev) => ({ ...prev, sports }));
  },
);

export const sportIdDirAtom = atom((get) => {
  const all = get(sportAtom);
  return _.chain(all.sport)
    .concat(all.eSport)
    .keyBy((x) => x.sportId)
    .value();
});

export const tournamentAtom = atom(
  (get) => get(_sportAtom).tournaments,
  async (get, set) => {
    const lang = get(localeAtom);
    const tournaments = await getAllTournaments(lang);
    set(_sportAtom, (prev) => ({ ...prev, tournaments }));
  },
);

export const favSportsAtom = atom(
  (get) => {
    const all = get(_sportAtom).favSports;

    return _.keyBy([...all.sport, ...all.eSport], (x) => x.sportId);
  },
  async (get, set) => {
    const lang = get(localeAtom);
    const favSports = await getFavSports(lang);
    set(_sportAtom, (prev) => ({ ...prev, favSports }));
  },
);

export const favTournamentsAtom = atom(
  (get) => get(_sportAtom).favTournaments,
  async (get, set) => {
    const lang = get(localeAtom);
    const favTournaments = await getFavTournaments(lang);
    set(_sportAtom, (prev) => ({ ...prev, favTournaments }));
  },
);

export const liveCountAtom = atom(
  (get) => {
    const all = get(_sportAtom);

    const sport = _.sumBy(all.sports.sport, (x) => x.numberOfFixtureLive);
    const eSport = _.sumBy(all.sports.eSport, (x) => x.numberOfFixtureLive);

    return { sport, eSport };
  },
  (get, set) => {
    set(sportAtom);
  },
);

export const toggleFavSportAtom = atom(
  null,
  async (get, set, sportId: string) => {
    await toggleFavSport(sportId);
    await set(favSportsAtom);
  },
);

export const toggleFavTourAtom = atom(
  null,
  async (get, set, tournamentId: string | undefined) => {
    await toggleFavTournament(tournamentId);
    await set(favTournamentsAtom);
  },
);

export const clearFavAtom = atom(null, (get, set) => {
  set(_sportAtom, (prev) => ({ ...prev }));
});
