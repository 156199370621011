import { AxiosHeaders } from 'axios';
import { IGNORE_UNAUTHORIZED_HEADER } from 'src/utils/constants/keys';
import { Paths } from 'src/utils/constants/path';
import { getResponseMsg } from 'src/utils/constants/response-code';
import { getWindow } from 'src/utils/helpers/etc';

export interface MyError {
  code: string;
  statusCode: number | null;
  message: string;
  data: any;
  ignoreUnauthorized: boolean;
}

function minTwoDigits(n: any) {
  return (+n < 10 ? '0' : '') + n;
}

export const concreteMyError = (err: any) => {
  const headers: AxiosHeaders = err?.config?.headers || {};
  const data = err?.response?.data || {};
  const statusCode = data?.statusCode ?? null;
  const codeAsNumber = +(data?.code ?? '99');
  const errorCode = Number.isNaN(codeAsNumber)
    ? '99'
    : String(minTwoDigits(codeAsNumber));
  data['code'] = errorCode;
  data['statusCode'] = statusCode;

  const payload: MyError = {
    ...data,
    code: errorCode,
    statusCode: statusCode,
    message: getResponseMsg(data?.code),
    ignoreUnauthorized: headers.has?.(IGNORE_UNAUTHORIZED_HEADER) ?? false,
  };

  return payload;
};

export const isUnauthorizedError = (err: MyError) => {
  if (err.ignoreUnauthorized) return false;

  if (!err.statusCode) return false;

  if (err.code === '69') {
    return true;
  }

  if ([401, 403].includes(err.statusCode)) {
    return true;
  }

  return false;
};

export const isGEOBlocked = (err: MyError) => {
  const isBlog = getWindow()?.location.pathname.startsWith(Paths.Blogs);
  if (err.code === '102' && !isBlog) {
    return true;
  }

  return false;
};
