import { ErrorTypes } from 'src/components/modules/bet-slip-drawer/components/shared/error-message';
import { BaseEvent, FixtureStatus } from './event';
import { MyBetStatus } from './my-bet-type';
import { BaseOutcome } from './outcome';
import { RoomType } from './socket-type';

export enum BetSlipModeType {
  Single = '1',
  Multi = '2',
  MyBets = '3',
}

export enum BetSource {
  BET_RADAR = 'BETRADAR',
  ODDIN = 'ODDIN',
  CAMPAIGN = 'CAMPAIGN',
}

export enum BetRadarURN {
  SR_MATCH = 'sr:match',
  SR_SEASON = 'sr:season',
  SR_STAGE = 'sr:stage',
  SR_TOURNAMENT = 'sr:tournament',
  SR_SIMPLE_TOURNAMENT = 'sr:simple_tournament',
}

export enum OddinURN {
  OD_MATCH = 'od:match',
  OD_TOURNAMENT = 'od:tournament',
}

export type BsError = null | {
  code?: ErrorTypes | string | number;
  params?: Record<string, any>;
};

export interface BetSlipItemInterface extends BaseOutcome {
  alias: string;
  // fixture stuffs
  eventName?: string;
  _rudderstack: Pick<BaseEvent, 'sportId' | 'tournamentId'>;

  // this is still be calculated even the user selects MULTI tab
  singleStake?: string;

  singleStakeRange?: {
    min?: string | number;
    max?: string | number;
  };

  // errors
  singleStakeError?: BsError;
  canceledError?: true | BsError;

  sameFixtureError?: boolean;
  crossBetError?: boolean;
  altStakeError?: boolean;
  preTicketId?: string;
  altStake?: string;
}
export interface InactiveBetGameError {
  betGameId: string;
  code: string | number;
  minStake?: string | number;
  maxStake?: string | number;
}
export interface OddsChangeError {
  bet_game_id: string;
  odds: number;
  betGameId: string;
}

export interface GetOutcomeIdRequest {
  event_id: string;
  market_id: string;
  market_specifiers?: string | null;
  outcome_id: string;
  producer_id: string;
}

export interface GetOutcomeIdResponse {
  _id: string;
  eventId: string;
  marketId: string;
  marketSpecifiers: string;
  outcomeId: string;
  producerId: number;
  key: string;
}
export interface PlacedTicket {
  aliasFe: string;
  cashoutValue: any;
  content_builder: any;
  createdAt: string;
  currentSnapshotHash: any;
  estimateEarning: string;
  fee: string;
  flag: any;
  id: string;
  listening_mts: any;
  mtsStake: any;
  note: any;
  placeTotalOdds: string;
  price: string;
  realEarning: string;
  reason: any;
  roomId: string;
  roomType: RoomType;
  stake: string;
  status: MyBetStatus;
  totalGames: number;
  totalOdds: string;
  trigger: string;
  type: string;
  updatedAt: string;
  userId: number;
}

export interface EventTimeRanges {
  startTimeMin: string;
  startTimeMinId: string;
  startTimeMax: string;
  startTimeMaxId: string;
}

export interface GetFixtureListRequest {
  slug?: string;
  status?: FixtureStatus;
  fixturePopularMarket?: string;
  tournamentId?: string;
  page_index: number;
  page_size: number;
  sportId_like?: TypeSport;
  marketId?: string;
  lang?: string;
  __ranges?: EventTimeRanges;
}

export enum TypeSport {
  SPORT = 'sr',
  ESPORT = 'od',
}
