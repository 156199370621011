import { Moment } from 'moment';
import {
  AccountLevel,
  AuthStatus,
  RoleType,
  VerifyType,
} from 'src/utils/types/enums';
import { BaseResponse } from './response';

interface RegisterForm {
  username: string;
  email: string;
  password: string;
  promo_code?: string;
  inviteCode?: string;
  balance_available?: string;
  betu_balance?: string;
  deposit_address?: string;
  balance?: string;
  kyc_status?: string;
  lastLogin?: string;
  limit_type?: string | number;
  limit_value?: string | null;
  recaptcha?: string;
  domain?: string;
}

export interface UserRegisterForm
  extends Pick<
    RegisterForm,
    | 'email'
    | 'password'
    | 'recaptcha'
    | 'promo_code'
    | 'domain'
    | 'limit_type'
    | 'limit_value'
  > {
  countryCode?: { name: string; code: string } | null;
  acceptTerms?: boolean;
  phone_number?: string;
  verify_type?: VerifyType;
  date_of_birth: Moment | string | null;

  freespin_promo_code?: string;

  affiliate_code?: string;
}

interface UserPayload {
  role: RoleType | null;
  userId: number | null;
  jwt: string | null;
  fingerprint: string | null;
  prefer: string | null;
  locale: string;
}

interface UserHoldingData {
  casinoEdgebackRate: string;
  edgebackAvailable: string;
  inHoldingValue: string;
  name: string;
  rank: string;
  sportEdgebackRate: string;
  sportLossbackRate: string;
  casinoLossbackRate: string;
  unlockTime: string;
  lossbackExpiredTime: string;
  lossbackIsClaimed: boolean;
  lossbackValue: string;

  lossbackScheduleTime: string;
}

interface AuthData {
  access_token: string;
  expired_in: number;
  user_id: string;
}
export type AuthResponse = BaseResponse<AuthData>;

export interface ProfileData {
  id: number;
  email: string;
  username: string;
  phoneNumber: string;

  inviteCode: any;
  promotionCode: string;
  referenceId: string;
  status: AuthStatus;
  createdAt: string;
  updatedAt: string;
  role: RoleType;
  enabled2fa: boolean;
  timeSelfExclude: string;
  limit_type: string;
  limit_value: string | null;
  start_time: string;

  userHolding: UserHoldingData;
  userBonusBet?: IUserBonusBet;
  enablePrivateProfile?: boolean;
  hasUsernameChanged: boolean;
  emailVerified: boolean;
  emailVerifyExpireAt: number;
  selectedCoinSymbol: string | null;
  deposited: boolean;

  userProfile: UserProfile;
}

interface UserProfile {
  id: number;
  userId: number;
  avatar: any;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  nationalIdNo: string;
  website: string;
  address: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  occupation: string;
  facebookSite: string;
  instagramSite: string;
  twitterSite: string;
  totalWinningBetu: string;
  totalRewardEarnu: string;
  totalDeposit: string;
  accountLevel: string;
  selectedCoinSymbol: string;
  createdAt: string;
  updatedAt: string;
}

export type GetProfileResponse = BaseResponse<ProfileData>;

export type GetAccountInfoResponse = BaseResponse<IUpdateAccountLevel>;

export type GetBlockCountries = BaseResponse<IBlockCountry[]>;

interface PromoCodeData {
  promo_code_existed?: boolean;
}
export type PromoCodeResponse = BaseResponse<PromoCodeData>;

interface AvailableData {
  available_balance?: string;
  available_balance_usdt?: string;
  total_balance_usdt?: string;
  total_balance?: string;
}

export enum KYCStatusKey {
  NOT_KYC = 'NOT_KYC',
  VERIFYING = 'VERIFYING',
  VERIFY_FAIL = 'VERIFY_FAIL',
  KYC_DONE = 'KYC_DONE',
  IDL = 'IDL', //  for loading
}

export type EnableSelfExcludeResponse = BaseResponse<{ date: string }>;
export interface ResetPasswordForm {
  new_password: string;
  confirm_password: string;
  token: string;
}
export interface ForgotPasswordForm {
  email: string;
  recaptcha: string;
}
export interface LoginForm {
  email: string;
  password: string;
  rememberMe: boolean;
  recaptcha: string;
  domain?: string;
}
export type LoginResponse = BaseResponse<{
  access_token: string;
  expired_at?: number;
  user_id: string;
  role: RoleType;
}>;

export interface IUpdateAccountLevel {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  accountLevel?: AccountLevel;
}

export interface IBlockCountry {
  countryCode2: string;
  countryCode3: string;
  countryName: string;
  createdAt: string;
  id: number;
  updatedAt: string;
}

interface IUserBonusBet {
  id: number;
  voucherExpiredTime: string;
  claimExpiredTime: string;
  value: string;
  status: BonusBetStatusEnum;
  coin: string;
}

enum BonusBetStatusEnum {
  NOT_CREATE = 'NOT_CREATE',
  CREATED = 'CREATED',
  CLAIMABLE = 'CLAIMABLE',
  CLAIMED = 'CLAIMED',
  PENDING = 'PENDING',
  USED = 'USED',
  CLAIM_EXPIRED = 'CLAIM_EXPIRED',
  USAGE_EXPIRED = 'USAGE_EXPIRED',
}

export interface ISetPrivateProfile {
  enablePrivate: boolean;
}
