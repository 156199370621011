import { Trans } from '@lingui/macro';
import dynamic from 'next/dynamic';
import { ReactNode, SVGProps } from 'react';
import { GetGamesParams } from 'src/utils/types/casino';

export enum FeatureKeys {
  JACKPOT = 1,
  FREE_SPIN = 2,

  VOLATILITY_HIGH = 3,
  VOLATILITY_MEDIUM = 4,
  VOLATILITY_LOW = 5,

  MULTIPLIER_HIGH = 6,
  MULTIPLIER_MEDIUM = 7,
  MULTIPLIER_LOW = 8,
}

interface FeatureOption {
  label?: React.ReactNode;
  Icon?: React.FC<SVGProps<SVGSVGElement>>;
  filter: { [key: string]: boolean } | string[] | number;
  key: FeatureKeys;
}

const FREE_OPTIONS = {
  items: [
    {
      label: <Trans id="feature.jackpot">Jackpot</Trans>,
      Icon: dynamic(() => import('src/assets/icons/dollar.svg')),
      key: FeatureKeys.JACKPOT,
      filter: { hasJackpot: true },
    },
    {
      label: <Trans id="feature.free-spin">Free spin feature</Trans>,
      Icon: dynamic(() => import('src/assets/icons/free.svg')),
      key: FeatureKeys.FREE_SPIN,
      filter: { hasFreespins: true },
    },
  ] as FeatureOption[],
};

const VOLATILITY_OPTIONS = {
  label: <Trans id="feature.volatility">Volatility</Trans>,
  items: [
    {
      label: (
        <Trans id="feature.volatility-high">
          High - Bigger payouts, less often.
        </Trans>
      ),
      key: FeatureKeys.VOLATILITY_HIGH,
      Icon: dynamic(() => import('src/assets/icons/high-volatility.svg')),
      filter: ['high', 'very-high'],
    },
    {
      label: (
        <Trans id="feature.volatility-medium">
          Medium - Medium payouts, most often.
        </Trans>
      ),
      key: FeatureKeys.VOLATILITY_MEDIUM,
      Icon: dynamic(() => import('src/assets/icons/medium-volatility.svg')),
      filter: ['medium', 'medium-high'],
    },
    {
      label: (
        <Trans id="feature.volatility-low">
          Low - Smaller payouts, more often.
        </Trans>
      ),
      key: FeatureKeys.VOLATILITY_LOW,
      Icon: dynamic(() => import('src/assets/icons/low-volatility.svg')),
      filter: ['low-medium', 'low'],
    },
  ] as FeatureOption[],
};

enum MULTIPLIER_MARKS {
  HIGH = 1,
  MEDIUM = 2,
  LOW = 4,
}

// only 3 options
const MULTIPLIER_OPTIONS = {
  label: <Trans id="feature.multiplier">Win Multiplier</Trans>,
  items: [
    {
      label: <Trans id="feature.multiplier-high">Over 65k</Trans>,
      filter: MULTIPLIER_MARKS.HIGH,
      key: FeatureKeys.MULTIPLIER_HIGH,
    },
    {
      label: <Trans id="feature.multiplier-medium">Between 30k and 65k</Trans>,
      filter: MULTIPLIER_MARKS.MEDIUM,
      key: FeatureKeys.MULTIPLIER_MEDIUM,
    },
    {
      label: <Trans id="feature.multiplier-low">Under 30k</Trans>,
      filter: MULTIPLIER_MARKS.LOW,
      key: FeatureKeys.MULTIPLIER_LOW,
    },
  ] as FeatureOption[],
};

export const FEATURE_OPTIONS: { label?: ReactNode; items: FeatureOption[] }[] =
  [FREE_OPTIONS, VOLATILITY_OPTIONS, MULTIPLIER_OPTIONS];

const MEDIUM_HIGH = MULTIPLIER_MARKS.HIGH | MULTIPLIER_MARKS.MEDIUM;
const MEDIUM_LOW = MULTIPLIER_MARKS.MEDIUM | MULTIPLIER_MARKS.LOW;
const HIGH_LOW = MULTIPLIER_MARKS.HIGH | MULTIPLIER_MARKS.LOW;
const ALL =
  MULTIPLIER_MARKS.HIGH | MULTIPLIER_MARKS.MEDIUM | MULTIPLIER_MARKS.LOW;

const getMultiplierRange = (input: number) => {
  if ((input & ALL) === ALL)
    return {
      multiplier_gte: 0,
    };

  if ((input & MEDIUM_HIGH) === MEDIUM_HIGH)
    return {
      multiplier_gte: 30000,
    };

  if ((input & MEDIUM_LOW) === MEDIUM_LOW)
    return {
      multiplier_lte: 65000,
    };

  if ((input & HIGH_LOW) === HIGH_LOW)
    return {
      multiplier_or: 'lt_30000,gt_65000',
    };

  if ((input & MULTIPLIER_MARKS.HIGH) === MULTIPLIER_MARKS.HIGH)
    return {
      multiplier_gt: 65000,
    };

  if ((input & MULTIPLIER_MARKS.MEDIUM) === MULTIPLIER_MARKS.MEDIUM)
    return {
      multiplier_lte: 65000,
      multiplier_gte: 30000,
    };

  if ((input & MULTIPLIER_MARKS.LOW) === MULTIPLIER_MARKS.LOW)
    return {
      multiplier_lt: 30000,
    };

  return {};
};

export const CasinoFeatures__serialize = (
  params: GetGamesParams,
): Partial<GetGamesParams> => {
  const { __features = [] } = params;
  const free = FREE_OPTIONS.items.reduce((prev, x) => {
    if (!__features.includes(x.key)) return prev;
    return { ...prev, ...(x.filter as { [key: string]: boolean }) };
  }, {});

  const volatilityRating_in = VOLATILITY_OPTIONS.items.reduce((prev, x) => {
    if (!__features.includes(x.key)) return prev;
    return [...prev, x.filter as string[]];
  }, [] as string[][]);

  const multiplier = MULTIPLIER_OPTIONS.items.reduce((prev, x) => {
    if (!__features.includes(x.key)) return prev;

    return prev | (x.filter as number);
  }, 0);

  const multiplierRange = getMultiplierRange(multiplier);

  return {
    ...free,
    ...multiplierRange,
    volatilityRating_in,
  };
};
