/* eslint-disable eqeqeq */
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import moment from 'moment';
import { OddsAcceptance } from 'src/store/layout';
import {
  BaseEvent,
  FixtureStatus,
  Nullable,
  TvChannel,
} from 'src/utils/types/event';
import {
  BaseOutcome,
  OutcomeActive,
  ProducerId,
} from 'src/utils/types/outcome';
import { BetRadarV5, brEvent } from 'src/utils/types/socket-type';
import { BetRadarURN, BetSource, OddinURN } from 'src/utils/types/sportsbook';
import { BrMarketStatus, PRODUCER_WEIGHT } from '../constants/common-constant';
import { BN } from './big-number';

export const toStartCase = (...strings: Array<string | undefined>) => {
  const next = _.chain(strings)
    .compact()
    .join(' ')
    // a b - / (
    .replace(/(^|\W)(\w)/g, _.toUpper)
    .trim()
    .value();

  return next;
};

export const isUpcoming = (fixture: BaseEvent) => {
  return fixture.status === FixtureStatus.NOT_STARTED;
};

export const isEnded = (fixture: BaseEvent) => {
  return [FixtureStatus.ENDED, FixtureStatus.CLOSED].includes(fixture.status);
};

export const isLive = (fixture: BaseEvent) => {
  return fixture.status === FixtureStatus.LIVE;
};

/**
 * Condition for oddin matchs, might be changed in the future
 * @param fixture
 * @returns true if match is live and has at least 1 tv channel
 */
export const isWatchableLive = (fixture: BaseEvent) => {
  if (!isOddin(fixture?.eventId)) return false;
  if (_.isEmpty(fixture.tvChannels)) return false;

  return isLive(fixture);
};

/**
 * Parser for odds from odds_change, rollback_fixture, fixture_change
 */
export const parseOdds = (
  _odds: Nullable<BaseOutcome>[] | undefined,
  msg: BetRadarV5.OddChange,
): Nullable<BaseOutcome>[] => {
  if (_.every(_odds, _.isNil)) return _odds ?? [];
  const odds = (_odds ?? []) as BaseOutcome[];

  const firstOdd = _.chain(odds).first();

  const marketId = firstOdd.get('marketId').value();

  const marketSpecifier = firstOdd.get('marketSpecifiers').value();

  const _matchedMarket = _.chain([msg.odds])
    .flatMap((o) => o?.market)
    .compact()
    .find(
      (m) =>
        m._id === marketId && compareSpecifiers(m._specifiers, marketSpecifier),
    )
    .value();

  if (!_matchedMarket) {
    return odds;
  }

  const _product = +msg._product;
  const nextWeight = _.get(PRODUCER_WEIGHT, [msg._product]);

  const next = _.map(odds, (outcome) => {
    const _producerId = +outcome.producerId;

    // inactive all outcome having producerId not match to msg._product
    if (+_product !== +_producerId) {
      outcome.outcomeActive = OutcomeActive.INACTIVE;
    }

    const currentWeight = _.get(PRODUCER_WEIGHT, _producerId);
    // LIVE gonna LDO or UPCOMING gonna LIVE
    // heaviest weight will be popped up
    if (nextWeight > currentWeight) {
      outcome.producerId = _product as ProducerId;
      outcome._id = null;
    }

    // if current is LDO, ignore LIVE
    // if current is LIVE, ignore UPCOMING
    // just in case the messages are sent not in order
    if (currentWeight > nextWeight) {
      return {
        ...outcome,
        outcomeActive: OutcomeActive.INACTIVE,
      };
    }

    if (_matchedMarket._status !== BrMarketStatus.ACTIVE) {
      return { ...outcome, outcomeActive: OutcomeActive.INACTIVE };
    }

    const matchedOutcome = _.flatten([_matchedMarket.outcome]).find(
      (item) => item?._id === outcome.outcomeId,
    );

    if (!matchedOutcome) {
      return outcome;
    }

    let outcomeActive =
      +matchedOutcome._active === +OutcomeActive.ACTIVE
        ? OutcomeActive.ACTIVE
        : OutcomeActive.INACTIVE;
    if (_.isUndefined(matchedOutcome._odds)) {
      outcomeActive = OutcomeActive.INACTIVE;
    }

    return {
      ...outcome,
      currentOdd: +matchedOutcome._odds || outcome.currentOdd,
      outcomeActive,
      marketStatus: _matchedMarket._status,
    };
  });

  return next;
};

type Falsy<T> = null | undefined | T;
export const compareSpecifiers = (a: Falsy<string>, b: Falsy<string>) => {
  // ! null == undefined => true
  // eslint-disable-next-line eqeqeq

  if (!_.isNil(a) && !_.isNil(b)) {
    if (a.length !== b.length) return false;

    return (
      _.chain(a).split('|').sortBy().join('|').value() ===
      _.chain(b).split('|').sortBy().join('|').value()
    );
  }
  return a == b;
};

const OddinToBr = {
  [ProducerId.ODDIN_LIVE]: ProducerId.LIVE,
  [ProducerId.ODDIN_PREMATCH]: ProducerId.PREMATCH,
};

export const mappingOddinToBr = (producerId: ProducerId) => {
  return _.get(OddinToBr, producerId, producerId).toString();
};

export const brStatusToFixtureStatus = {
  not_started: FixtureStatus.NOT_STARTED,
  live: FixtureStatus.LIVE,
  ended: FixtureStatus.ENDED,
  closed: FixtureStatus.CLOSED,
  cancelled: FixtureStatus.CANCELLED,
  postponed: FixtureStatus.POSTPONED,
  abandoned: FixtureStatus.ABANDONED,
  interrupted: FixtureStatus.INTERRUPTED,
  delayed: FixtureStatus.DELAYED,
};

export const isOddin = (_eventId?: string): _eventId is BetSource.ODDIN => {
  return _.startsWith(_eventId, 'od:');
};

export const refineBrFixture = (
  fixture: brEvent.Fixture,
): Partial<BaseEvent> => {
  const _isOddin = isOddin(fixture.id);

  const tvChannelsData = fixture?.tv_channels
    ? {
        tv_channel: _.map(fixture?.tv_channels, (o) => {
          return {
            _name: _.get(o, ['name']) as string,
            _language: _.get(o, ['language']) as string,
            _stream_url: _.get(o, ['stream_url']) as string,
          } satisfies TvChannel;
        }),
      }
    : undefined;

  const obj = {
    // super stuffs
    competitor1: _.get(fixture, ['competitors', 0, 'name']),
    competitor2: _.get(fixture, ['competitors', 1, 'name']),

    // child stuffs
    eventId: _.get(fixture, ['id']),
    tournamentId: _.get(fixture, ['tournament', 'id']),

    betSource: _isOddin ? BetSource.ODDIN : BetSource.BET_RADAR,
    startTime: moment(fixture.startTime ?? fixture.scheduled).toISOString(),
    status: _.get(
      brStatusToFixtureStatus,
      [fixture?.status],
      FixtureStatus.NOT_STARTED,
    ),
    tournament: fixture?.tournament,
    tvChannels: _isOddin ? fixture?.tvChannels ?? tvChannelsData ?? [] : [],
  };

  // to omit undefined values
  return JSON.parse(JSON.stringify(obj)) as Partial<BaseEvent>;
};

export const isOutright = (id?: string) => {
  if (!id) return false;

  if (id.startsWith(OddinURN.OD_MATCH)) return false;

  if (id.startsWith(BetRadarURN.SR_MATCH)) return false;

  return true;
};

type Ids = Nullable<
  Partial<
    Pick<
      BaseOutcome,
      | '_id'
      | 'eventId'
      | 'marketId'
      | 'marketSpecifiers'
      | 'outcomeId'
      | 'producerId'
    >
  >
>;

export const compareOutcome = (AAA: Ids, BBB: Ids) => {
  if (_.isNil(AAA?._id) || _.isNil(BBB?._id)) {
    return (
      AAA?.eventId == BBB?.eventId &&
      AAA?.marketId == BBB?.marketId &&
      compareSpecifiers(AAA?.marketSpecifiers, BBB?.marketSpecifiers) &&
      AAA?.outcomeId == BBB?.outcomeId &&
      AAA?.producerId == BBB?.producerId
    );
  }

  return AAA?._id === BBB?._id;
};

export const generateKey = (AAA: Ids) => {
  const key = [
    AAA?.eventId,
    AAA?.marketId,
    AAA?.marketSpecifiers,
    AAA?.outcomeId,
    AAA?.producerId,
  ].join('_');

  if (key === '____') return AAA?._id ?? null;

  return key;
};

export const hideTooltip = (
  eventId: string | undefined,
  specifier: string | null | undefined,
) => {
  if (isOutright(eventId ?? '')) return true;

  if (specifier?.includes('hcp') || specifier?.includes('total')) {
    return true;
  }

  return false;
};

export const excludeBetSource = (
  src: string | BetSource | undefined,
  producerId: string | number | ProducerId | undefined,
) => {
  if (!producerId || !src) return true;

  if (src === BetSource.ODDIN && +producerId === ProducerId.LIVE) {
    return true;
  }

  return false;
};

export const isVisibileOutcome = (o: BaseOutcome) => {
  /**
   * - market_status =0
   * + Hiển thị: không cần check đến outcome_active
   * >> Detail: hide outcome
   * >> Sportbook: N/A
   */
  if (o.marketStatus === BrMarketStatus.STOPPED) {
    return false;
  }

  /**
   * >> market_status = 1, outcome_active =1: detail/ sportbook_hiện outcome và odd như bình thường
   */
  if (
    o.marketStatus === BrMarketStatus.ACTIVE &&
    o.outcomeActive === OutcomeActive.ACTIVE
  ) {
    return true;
  }

  /**
   * >> market_status =-1 , outcome_active =1: detail/ sportbook_outcome hiện N/A
   * >> market_status = 1|-1 , outcome_active =0 : detail_ẩn outcome, sportbook_N/A( popular market)
   */
  return (
    o.outcomeActive === OutcomeActive.ACTIVE &&
    [BrMarketStatus.SUSPENDED, BrMarketStatus.ACTIVE].includes(o.marketStatus)
  );
};

export const isOddsChange = (payload: {
  acceptance: OddsAcceptance;
  prev: BigNumber.Value;
  next: BigNumber.Value;
}) => {
  const { acceptance, prev, next } = payload;

  if (acceptance === OddsAcceptance.ANY) {
    return false;
  }

  const diff = new BN(next).comparedTo(prev);
  if (!diff) return false;

  if (acceptance === OddsAcceptance.HIGHER) {
    return diff !== 1;
  }

  return !!diff;
};
