import { KeenSliderPlugin } from 'keen-slider/react';
import _ from 'lodash';
import { BASE_URL } from 'src/utils/constants/app';
import uaParser from 'ua-parser-js';
import { DEFAULT_LOCALE } from '../constants/keys';
import { TvChannel } from '../types/event';

export const isClient = () => {
  return typeof window !== 'undefined';
};

export const isServer = () => !isClient();

export const getWindow = () => {
  if (typeof window === 'undefined') return null;

  return window;
};

export const getStreamURL = (tvChannels?: TvChannel[]) => {
  if (!tvChannels?.length) return;

  const channel =
    _.find(tvChannels, { _language: DEFAULT_LOCALE }) || _.first(tvChannels);

  if (!channel || !channel._stream_url) return;

  const rawURL = new URL(channel._stream_url);

  const hostname = new URL(BASE_URL).hostname;

  if (channel._name === 'Twitch') {
    return `https://player.twitch.tv/?channel=${rawURL.pathname.slice(
      1,
    )}&parent=${hostname}`;
  }

  if (channel._name === 'Youtube') {
    return `https://www.youtube.com/embed/${rawURL.searchParams.get('v')}`;
  }

  return rawURL.href;
};

export const isIOS = () => {
  return uaParser().device.vendor === 'Apple';
};

export const checkTotalPage = (value: number, page_size = 10) => {
  return Math.ceil(value / page_size);
};

const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

const AdapteHeightPlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    const max = Math.max(...slider.slides.map((o) => o.offsetHeight));

    if (max > 0) {
      slider.container.style.height = `${max}px`;
    }
  });

  slider.on('slideChanged', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

export const KeenSliderPlugins = [ResizePlugin];

type Subscriber<T> = (next: T) => any;

export class Observable<T> {
  private observers: Subscriber<T>[];
  private _current: T | null = null;

  constructor() {
    this.observers = [];
  }

  subscribe(fn: Subscriber<T>) {
    this.observers.push(fn);
  }

  next(val: T) {
    this._current = val;
    this.observers.forEach((sub) => {
      sub(val);
    });
  }
  current() {
    return this._current;
  }
}

export const isValidHttpUrl = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

export enum MyDevices {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export const isDeviceType = (target: MyDevices) => {
  const device = uaParser().device.type;

  switch (device) {
    case 'mobile':
    case 'tablet':
      return target === MyDevices.MOBILE;

    default:
      return target === MyDevices.DESKTOP;
  }
};
