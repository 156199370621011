import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { BsError } from 'src/utils/types/sportsbook';

export enum ErrorTypes {
  Insufficient = '1',
  SomeErrors = '2',
  OddsChange = '3',
  LockedOrCancel = '4',
  Canceled = '5',
  MinStake = '6',
  SameFixture = '7',
  InvalidAmount = '8',
  SelfExclude = '9',
  KycError = '10',
  MaxBetError = '11',
  CrossBet = '12',
  RequiredLevel = '13',
  HasAltStake = '14',
  OfferedAltStake = '15',
  SpendingLimit = '16',
  MaxStake = '17',
  Campaign___Ended = '18',
  Campaign___Suspended = '19',
  Campaign___ExceedExpense = '20',
  Campaign___Blacklist = '21',
  Campaign___Limited = '22',
  Suspended = '23',
  Suspended_MTS_OTS = '24',
  Settled = '25',
}

const getBetslipErrMsg = (type: ErrorTypes | string | number, i18n: I18n) => {
  switch (String(type)) {
    case ErrorTypes.Insufficient:
      return t(i18n)({
        message: 'Insufficient Balance',
        id: 'bet_slip.error.insufficient',
      });
    case ErrorTypes.SomeErrors:
      return t(i18n)({
        message:
          'There are some errors with your bets, please fix them to continue.',
        id: 'bet_slip.error.some_errors',
      });
    case ErrorTypes.OddsChange:
      return t(i18n)({
        message: 'Odds have changed.',
        id: 'bet_slip.error.odds_change',
      });
    case ErrorTypes.LockedOrCancel:
      return t(i18n)({
        message:
          'Some bets have been locked/canceled. Please remove them to continue.',
        id: 'bet_slip.error.bet_cancelled',
      });
    // items
    case ErrorTypes.Canceled:
      return t(i18n)({
        message: 'Bet has been canceled. Please remove.',
        id: 'bet_slip.error.canceled',
      });
    case ErrorTypes.MinStake:
      return t(i18n)({
        message: 'Minimum stake is [amount].',
        id: 'bet_slip.error.required_stake',
      });
    case ErrorTypes.SameFixture:
      return t(i18n)({
        message:
          "Multi bet doesn't support multiple selections from the same event.",
        id: 'bet_slip.error.same_fixture',
      });
    case ErrorTypes.InvalidAmount:
      return t(i18n)({
        message: 'Invalid amount',
        id: 'error.invalid_amount',
      });

    case ErrorTypes.SelfExclude:
      return t(i18n)({
        message:
          'You cannot place any bets because your account is under excluded period. Please contact our support team for further assistance.',
        id: 'error.account_exclude',
      });

    case ErrorTypes.KycError:
      return t(i18n)({
        message: 'Please verify your identity to place bets.',
        id: 'bet_slip.error.kyc_error',
      });

    case ErrorTypes.MaxBetError:
      return t(i18n)({
        message:
          "You've added more than 63 folds on a multi bet. Please remove some of them to continue.",
        id: 'bet_slip.error.max_bet_error',
      });

    case ErrorTypes.CrossBet:
      return t(i18n)({
        message: "Can't combine sports, eSports & promo bets into a multi.",
        id: 'multi_bet.cross_bet',
      });

    case ErrorTypes.RequiredLevel:
      return t(i18n)({
        message:
          'Please complete Level [level] account verification to place a bet',
        id: 'bet_slip.error.required_level',
      });
    case ErrorTypes.HasAltStake:
      return t(i18n)({
        message:
          'Stake limit has been reached for this outcome. Please accept Alternative Stake',
        id: 'bet_slip.error.has_alt_stake',
      });
    case ErrorTypes.OfferedAltStake:
      return t(i18n)({
        message: 'Alternative Stake Offered',
        id: 'bet_slip.error.alt_stake',
      });
    case ErrorTypes.SpendingLimit:
      return t(i18n)({
        message: `Your spending limit has been exceeded. Please lower the stakes or come back later. You have [remain] [coin] (~ [remainInUsd] USD) remaining [period].`,
        id: 'bet_slip.error.spending_limit',
      });

    case ErrorTypes.MaxStake:
      return t(i18n)({
        id: 'bet_slip.error.max_stake',
        message: 'Maximum stake is [amount]',
      });

    case ErrorTypes.Campaign___Ended:
      return t(i18n)({
        id: 'bet_slip.error.campaign.ended',
        message: 'Bet has been expired. Please remove this bet to continue',
      });
    case ErrorTypes.Campaign___Suspended:
      return t(i18n)({
        id: 'bet_slip.error.campaign.suspended',
        message: 'Bet has been suspended. Please remove this bet to continue.',
      });
    case ErrorTypes.Campaign___ExceedExpense:
      return t(i18n)({
        id: 'bet_slip.error.campaign.exceed_expanse',
        message: 'Promotion limit reached for this outcome.',
      });
    case ErrorTypes.Campaign___Blacklist:
      return t(i18n)({
        id: 'bet_slip.error.campaign.blacklist',
        message: 'Promo bets are not supported in your country.',
      });
    case ErrorTypes.Campaign___Limited:
      return t(i18n)({
        id: 'bet_slip.error.campaign.limited',
        message: 'Promotion limit reached - 1 bet per person per campaign.',
      });
    case ErrorTypes.Suspended:
      return t(i18n)({
        id: 'bet_slip.error.bet_suspended',
        message: 'Bet suspended, hold tight for new odds.',
      });
    case ErrorTypes.Suspended_MTS_OTS:
      return t(i18n)({
        id: 'bet_slip.error.bet_suspended_mts_ots',
        message: 'Market suspended',
      });
    default:
      return null;
  }
};

const replaceErrorMessage = (str: string, params?: Record<string, any>) => {
  if (!str) return str;

  return str.replace(/\[([^\]]*)\]/g, (__, key) => {
    const clean = key.replace(/[\[\]]/g, '');

    return params?.[clean] ?? clean;
  });
};

export const parseBsError = (err: BsError & { i18n: I18n }) => {
  if (!err?.code) return null;

  let msg = getBetslipErrMsg(err?.code, err.i18n);
  if (!msg) return null;

  if (err.params) {
    msg = replaceErrorMessage(msg, err.params);
  }

  return msg;
};
