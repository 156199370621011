/* eslint-disable lingui/no-unlocalized-strings */
import axios from 'axios';
import qs from 'qs';
import { concreteMyError } from 'src/utils/helpers/my-error';
import { isClient } from '../helpers/etc';
import { clientJWT } from '../helpers/headers/headers.client';
import { serverJWT } from '../helpers/headers/headers.server';

export const request = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  paramsSerializer: (params) =>
    qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
});

request.interceptors.request.use(async (config) => {
  try {
    const browser = isClient();

    const jwt = await (browser ? clientJWT() : serverJWT());
    if (jwt) {
      config.headers.set('Authorization', `Bearer ${jwt}`);
    }

    if (!browser) {
      config.timeout = 1500;
    }
  } catch (err) {}
  return config;
});

request.interceptors.response.use(undefined, (err: any) => {
  const payload = concreteMyError(err);

  return Promise.reject(payload);
});
